import React from "react";
import styles from "./RecipeBuilder.module.scss";
import Slider from "./Slider.js";
import client from "../services/contentful.js";
import ModalPopup from "./ModalPopup/ModalPopup.jsx";
import { marked } from "marked";
import nollstall from "../assets/icons/nolstall.png";
import RecipePagination from "./RecipePagination/RecipePagination";
import BottleConfigurator from "./BottleConfigurator";
import HerbPagination from "./HerbPagination/HerbPagination";
import Cookies from "universal-cookie";
import Overview from "./Overview/Overview";
import { Link } from "react-router-dom";

import cartRecept from "../assets/images/20_varukorg_120x120_03.png";
import trash from "../assets/icons/trash-alt.svg";

import { Tabs, Tab } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import cartImg from "../assets/icons/cartIcon.png";
import Confirmation from "./Confirmation";
import GiftCard from "./GiftCard/GiftCard";
import WizardConf from "./Wizard/WizardConf";
import WizardConfMangd from "./Wizard/WizardConfMangd";

const tabNameToIndex = {
  0: "recepten",
  1: "valj_botanicals",
  2: "mangd_botanicals",
  3: "typ_och_styrka",
  4: "presentkort",
  5: "tack_for_din_bestallning",
  6: "wizard",
  7: "wizard_mangd",
  8: "wizard_typ",
};
const indexToTabName = {
  recepten: 0,
  valj_botanicals: 1,
  mangd_botanicals: 2,
  typ_och_styrka: 3,
  presentkort: 4,
  tack_for_din_bestallning: 5,
  wizard: 6,
  wizard_mangd: 7,
  wizard_typ: 8,
};
const StyledTabs = withStyles({
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    "& > span": {
      maxWidth: 80,
      width: "100%",
      backgroundColor: "#E4572E",
    },
  },
})((props) => (
  <Tabs
    variant="scrollable"
    {...props}
    TabIndicatorProps={{ children: <span /> }}
  />
));
const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    color: "#000",
    fontWeight: "regular",
    fontSize: "18px",
    padding: "0",
    "&:focus": {
      opacity: 1,
      outline: "none",
      fontWeight: "bold",
    },
  },
}))((props) => <Tab disableRipple {...props} />);

const cookies = new Cookies();

class RecipeBuilder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      recipes: [],
      grundrecept: [],
      kundrecept: [],
      ggsrecept: [],
      availableHerbs: [],
      allHerbs: [],
      wizardHerbs: [],
      wizardGroups: [],
      selectedRecipe: { herbs: [] },
      selectedRecipeCopy: { herbs: [] },
      orgSelectedRecipe: { herbs: [] },
      recipeSelectedTest: { herbs: [] },
      selectedRecipeId: "",
      selectedNewHerbId: "",
      temp1: {},
      temp2: {},
      herbsStrength: {},
      selectedBottlesOption: "4",
      selectedTemperatureCount: "2",
      authInfo: props.data,
      guideKryddor: {},
      openModalOne: false,
      openModalTwo: false,
      selectRecipe: "",
      currentRecipeID: "",
      isAdmin: false,
      selectedTab: 0,
      contentfulRecept: [],
      contentfulBotanicals: [],
      contentfulMangd: [],
      contentfulTypOchStyrka: [],
      sliderDecDisabled: false,
      sliderIncDisabled: false,
      cartInfo: [],
      cartOpen: false,
      priceList: [],
      totalCost: "",
      giftcardarray: [{ name: "Presentkort", amount: 800 }],
      cartamount: 0,
      updateRecipeBtn: false,
      cartBottles: [],
      consulationWanted: localStorage.getItem("consultation-wanted"),
    };
    this.onChange = this.onChange.bind(this);
    this.onAddHerb = this.onAddHerb.bind(this);
    this.fetchWithSessionId = this.fetchWithSessionId.bind(this);
    this.showHerbs = this.showHerbs.bind(this);
    this.resetRatio = this.resetRatio.bind(this);
    this.restart = this.restart.bind(this);
    this.resetSelectedRecipe = this.resetSelectedRecipe.bind(this);
  }

  fetchWithSessionId(path) {
    if (cookies.get("recipe-session-id")) {
      cookies.set("recipe-session-id", cookies.get("recipe-session-id"), {
        path: "/",
        maxAge: 1740,
        sameSite: "none",
        secure: true,
      });
    }
    return fetch(path, {
      method: "GET",
      headers: {
        "X-Session-Id": cookies.get("recipe-session-id"),
      },
    });
  }

  componentDidMount() {
    this.loadComponent();

    client.getEntry("6oaW3es70zBoqcgsNfWhXg").then((entry) => {
      this.setState({
        guideKryddor: entry.fields,
      });
    });

    setTimeout(() => {
      this.fetchCart();
    }, 2000);

    let userData = sessionStorage.getItem("userData");

    if (userData) {
      this.setState({ isAdmin: JSON.parse(userData).customer.admin });
    }

    this.setState({
      selectedTab: indexToTabName[this.props.match.params.page],
    });

    /* if (this.props.data.confirmationLoaded) {
      setTimeout(() => {
        this.restart();
        this.loadRecipes();
      }, 3000);
    } */
  }

  loadRecipes() {
    this.fetchWithSessionId("publicapi/recipes/created")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        let recipesFromApi = data.map((recipe) => {
          return {
            value: recipe.id,
            display: recipe.name,
            key: recipe.id,
            url: recipe.url,
            type: recipe.type
          };
        });
        let kundrecept = [];
        let grundrecept = [];
        let ggsrecept = [];
        recipesFromApi.map((recipe) => {
          if (recipe.type === 0) {
            kundrecept.push(recipe)
          } else if (recipe.type === 1) {
            grundrecept.push(recipe)
          } else if (recipe.type === 2) {
            ggsrecept.push(recipe)
          }
        });
        this.setState({
          recipes: recipesFromApi,
          grundrecept: grundrecept,
          kundrecept: kundrecept,
          ggsrecept: ggsrecept
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  loadComponent() {
    this.loadRecipes();
    this.fetchPriceList();
    this.getWizardGroups();
    let recipeId = sessionStorage.getItem("gin-recipeid");

    if (recipeId) {
      if (cookies.get("recipe-session-id")) {
        this.fetchWithSessionId(`publicapi/recipes/${recipeId}`)
          .then((response) => {
            if (response.status === 401) {
              window.location.href = "/ginshoppen";
            }
            return response.json();
          })
          .then((data) => {
            this.setState({
              orgSelectedRecipe: data,
            });
            return data;
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }

    let change = {
      temperature1: 0,
      temperature2: 0,
      temperature3: 0,
      temperatureCount: 0,
    };

    const putMethod = {
      method: "PUT",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "X-Session-Id": cookies.get("recipe-session-id"),
      },
      body: JSON.stringify(change),
    };

    //Get SessionID
    if (cookies.get("recipe-session-id")) {
      const route = "/publicapi/Recipes/settings";
      fetch(route, putMethod)
        .then((response) => {
          if (response.status === 401) {
            window.location.href = "/ginshoppen";
            return;
          } else if (response.status === 500) {
            this.restart();
          }
          return response.json();
        })
        .then((data) => {
          var tempSettings1 = {
            name: "Temp 1",
            min: 78.5,
            max: 96,
            step: 0.1,
            value: { min: data.temperature1, max: data.temperature2 },
          };
          var tempSettings2 = {
            name: "Temp 2",
            min: tempSettings1.min,
            max: tempSettings1.max,
            step: tempSettings1.step,
            value: {
              min: data.temperature1,
              max: data.temperature3 ?? tempSettings1.value.max,
            },
          };
          var herbsSetting = {
            name: "Styrka",
            min: data.herbsAmountMin,
            max: data.herbsAmountMax,
            step: 0.1,
            value: data.herbsAmount,
          };
          this.setState(
            {
              selectRecipe: data.name,
              selectedRecipe: data,
              selectedNewHerbId: "",
              temp1: tempSettings1,
              temp2: tempSettings2,
              herbsStrength: herbsSetting,
              selectedTemperatureCount: data.temperatureCount.toString(),
            },
            () => {
              this.props.onKalle(data, 78.5, 96, 96, 2);
            }
          );
          return data;
        })
        .then((recipe) => {
          this.fetchWithSessionId("publicapi/herbs")
            .then((response) => {
              return response.json();
            })
            .then((data) => {
              let herbsFromApi = data.map((herb) => {
                let selected =
                  recipe.herbs.filter((h) => h.id === herb.id).length > 0;
                return {
                  value: herb.id,
                  suggestedValue: herb.suggestedValue,
                  category: herb.categoryName,
                  display: herb.name,
                  key: herb.id,
                  id: herb.id,
                  deletable: herb.deletable,
                  selected: selected,
                  sortingOrder: herb.group.sortingOrder,
                  sortOrder: herb.sortOrder,
                  group: herb.group,
                  description: herb.description,
                  wizardgroupid: herb.wizardGroupId,
                  wizardsortorder: herb.wizardSortOrder,
                  imageUrl: herb.imageUrl,
                  videoUrl: herb.videoUrl,
                };
              });

              let wizardGroups = this.sortWizardHerbs(herbsFromApi);

              this.setState({
                allHerbs: herbsFromApi,
                wizardHerbs: wizardGroups,
              });
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      fetch("publicapi/recipes/initial", {
        method: "GET",
        "X-Session-Id": " ",
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          cookies.set("recipe-session-id", data.sessionId, {
            path: "/",
            maxAge: 1740,
            sameSite: "none",
            secure: true,
          });
          return data;
          /* var tempSettings1 = {
            name: "Temp 1",
            min: 78.5,
            max: 96,
            step: 0.1,
            value: { min: data.temperature1, max: data.temperature2 },
          };

          var tempSettings2 = {
            name: "Temp 2",
            min: tempSettings1.min,
            max: tempSettings1.max,
            step: tempSettings1.step,
            value: {
              min: data.temperature1,
              max: data.temperature3 ?? tempSettings1.value.max,
            },
          };

          var herbsSetting = {
            name: "Styrka",
            min: data.herbsAmountMin,
            max: data.herbsAmountMax,
            step: 0.1,
            value: data.herbsAmount,
          };

          this.setState(
            {
              selectedRecipe: data,
              selectedNewHerbId: "",
              temp1: tempSettings1,
              temp2: tempSettings2,
              herbsStrength: herbsSetting,
              selectedTemperatureCount: data.temperatureCount.toString(),
            },
            () => {
              this.props.onKalle(recipe, 78.5, 96, 96, 2);
            }
          );
          return data; */
        })
        .then((data) => {
          this.restart();
        }) /* 
        .then((recipe) => {
          this.fetchWithSessionId("api/herbs")
            .then((response) => {
              return response.json();
            })
            .then((data) => {
              let herbsFromApi = data.map((herb) => {
                let selected =
                  recipe.herbs.filter((h) => h.id === herb.id).length > 0;
                return {
                  value: herb.id,
                  category: herb.categoryName,
                  display: herb.name,
                  key: herb.id,
                  id: herb.id,
                  deletable: herb.deletable,
                  selected: selected,
                };
              });

              this.setState({
                allHerbs: herbsFromApi,
              });
            })
            .catch((error) => {
              console.log(error);
            });
        }) */
        .catch((err) => {
          console.log(err);
        });
    }

    const getReceptContent = () => {
      client.getEntry("6aU59l3NNCYegWxBYqvOpy").then((entry) => {
        this.setState({ contentfulRecept: entry.fields });
      });
    };
    const getBotanicalsContent = () => {
      client.getEntry("6OufdITHKWN9jWosxZHcab").then((entry) => {
        this.setState({ contentfulBotanicals: entry.fields });
      });
    };
    const getMangdContent = () => {
      client.getEntry("19JiOSSALm0cp5IyakkECm").then((entry) => {
        this.setState({ contentfulMangd: entry.fields });
      });
    };
    const getTypOchStyrkaContent = () => {
      client.getEntry("2Cp6g3ewCdsbIl1Ha0n6s9").then((entry) => {
        this.setState({ contentfulTypOchStyrka: entry.fields });
      });
    };

    getReceptContent();
    getBotanicalsContent();
    getMangdContent();
    getTypOchStyrkaContent();
  }

  fetchPriceList = () => {
    const postMethod = {
      method: "GET",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "X-Session-Id": cookies.get("recipe-session-id"),
      },
    };
    fetch("publicapi/settings/pricelist", postMethod)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        this.setState({ priceList: data });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getWizardGroups = () => {
    const getMethod = {
      method: "GET",
      headers: {
        "X-Session-Id": cookies.get("recipe-session-id"),
      },
    };

    fetch("publicapi/Group", getMethod)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        this.setState({ wizardGroups: data });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  sortWizardHerbs = (herbs) => {
    var wizardHerbs = [...herbs].sort(
      (a, b) => a.wizardsortorder - b.wizardsortorder
    );
    const wizardHerbArray = [];
    [...wizardHerbs].forEach((herb) => {
      if (herb.wizardsortorder !== null) {
        wizardHerbArray.push(herb);
      }
    });
    let wizardGroups = wizardHerbArray.reduce((r, a) => {
      r[a.wizardgroupid] = [...(r[a.wizardgroupid] || []), a];
      return r;
    }, {});

    return wizardGroups;
  };

  resetRatio() {
    const putMethod = {
      method: "PUT",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "X-Session-Id": cookies.get("recipe-session-id"),
      },
    };

    const route = "publicapi/recipes/reset";

    fetch(route, putMethod)
      .then((response) => {
        if (response.status === 401) {
          window.location.href = "/ginshoppen";
        }
        return response.json();
      })
      .then((data) => {
        var tempSettings1 = {
          name: "Temp 1",
          min: 78.5,
          max: 96,
          step: 0.1,
          value: { min: data.temperature1, max: data.temperature2 },
        };

        var tempSettings2 = {
          name: "Temp 2",
          min: tempSettings1.min,
          max: tempSettings1.max,
          step: tempSettings1.step,
          value: {
            min: data.temperature1,
            max: data.temperature3 ?? tempSettings1.value.max,
          },
        };

        var herbsSetting = {
          name: "Styrka",
          min: data.herbsAmountMin,
          max: data.herbsAmountMax,
          step: 0.1,
          value: data.herbsAmount,
        };

        this.setState(
          {
            selectedRecipe: data,
            selectedNewHerbId: "",
            temp1: tempSettings1,
            temp2: tempSettings2,
            herbsStrength: herbsSetting,
            selectedTemperatureCount: data.temperatureCount.toString(),
          },
          () => {
            this.props.onKalle(
              data,
              tempSettings1.value.min,
              tempSettings1.value.max,
              tempSettings2.value.max,
              this.state.selectedTemperatureCount
            );
          }
        );
        return data;
      })
      .then((recipe) => {
        this.fetchWithSessionId("publicapi/herbs")
          .then((response) => {
            if (response.status === 401) {
              window.location.href = "/ginshoppen";
            }
            return response.json();
          })
          .then((data) => {
            let herbsFromApi = data.map((herb) => {
              let selected =
                recipe.herbs.filter((h) => h.id === herb.id).length > 0;
              return {
                value: herb.id,
                suggestedValue: herb.suggestedValue,
                category: herb.categoryName,
                display: herb.name,
                key: herb.id,
                id: herb.id,
                deletable: herb.deletable,
                selected: selected,
                sortingOrder: herb.group.sortingOrder,
                sortOrder: herb.sortOrder,
                group: herb.group,
                description: herb.description,
                wizardgroupid: herb.wizardGroupId,
                wizardsortorder: herb.wizardSortOrder,
                imageUrl: herb.imageUrl,
                videoUrl: herb.videoUrl,
              };
            });

            let wizardGroups = this.sortWizardHerbs(herbsFromApi);

            this.setState({
              allHerbs: herbsFromApi,
              wizardHerbs: wizardGroups,
              openModalTwo: false,
            });

            this.props.onConfirmationLoaded(false);
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  restart() {
    const putMethod = {
      method: "PUT",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "X-Session-Id": cookies.get("recipe-session-id"),
      },
    };

    sessionStorage.removeItem("gin-recipeid");
    this.clearSelectedRecipe();

    fetch("publicapi/recipes/restart", putMethod)
      .then((response) => {
        if (response.status === 401) {
          window.location.href = "/ginshoppen";
        }
        return response.json();
      })
      .then((data) => {
        var tempSettings1 = {
          name: "Temp 1",
          min: 78.5,
          max: 96,
          step: 0.1,
          value: { min: data.temperature1, max: data.temperature2 },
        };

        var tempSettings2 = {
          name: "Temp 2",
          min: tempSettings1.min,
          max: tempSettings1.max,
          step: tempSettings1.step,
          value: {
            min: data.temperature1,
            max: data.temperature3 ?? tempSettings1.value.max,
          },
        };

        var herbsSetting = {
          name: "Styrka",
          min: data.herbsAmountMin,
          max: data.herbsAmountMax,
          step: 0.1,
          value: data.herbsAmount,
        };

        this.setState(
          {
            selectedRecipe: data,
            orgSelectedRecipe: { herbs: [] },
            selectedNewHerbId: "",
            temp1: tempSettings1,
            temp2: tempSettings2,
            herbsStrength: herbsSetting,
            selectedTemperatureCount: data.temperatureCount.toString(),
          },
          () => {
            this.props.onKalle(
              data,
              tempSettings1.value.min,
              tempSettings1.value.max,
              tempSettings2.value.max,
              this.state.selectedTemperatureCount
            );
          }
        );
        return data;
      })
      .then((recipe) => {
        this.fetchWithSessionId("publicapi/herbs")
          .then((response) => {
            if (response.status === 401) {
              window.location.href = "/ginshoppen";
            }
            return response.json();
          })
          .then((data) => {
            let herbsFromApi = data.map((herb) => {
              let selected =
                recipe.herbs.filter((h) => h.id === herb.id).length > 0;
              return {
                value: herb.id,
                suggestedValue: herb.suggestedValue,
                category: herb.categoryName,
                display: herb.name,
                key: herb.id,
                id: herb.id,
                deletable: herb.deletable,
                selected: selected,
                sortingOrder: herb.group.sortingOrder,
                sortOrder: herb.sortOrder,
                group: herb.group,
                description: herb.description,
                wizardgroupid: herb.wizardGroupId,
                wizardsortorder: herb.wizardSortOrder,
                imageUrl: herb.imageUrl,
                videoUrl: herb.videoUrl,
              };
            });

            let wizardGroups = this.sortWizardHerbs(herbsFromApi);

            this.setState({
              allHerbs: herbsFromApi,
              wizardHerbs: wizardGroups,
              openModalOne: false,
            });

            this.props.onConfirmationLoaded(false);
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  resetSelectedRecipe() {
    let recipeid = sessionStorage.getItem("gin-recipeid");

    if (recipeid) {
      this.fetchWithSessionId(`publicapi/recipes/${recipeid}`)
        .then((response) => {
          if (response.status === 401) {
            window.location.href = "/ginshoppen";
          }
          return response.json();
        })
        .then((data) => {
          var tempSettings1 = {
            name: "Temp 1",
            min: 78.5,
            max: 96,
            step: 0.1,
            value: { min: data.temperature1, max: data.temperature2 },
          };

          var tempSettings2 = {
            name: "Temp 2",
            min: tempSettings1.min,
            max: tempSettings1.max,
            step: tempSettings1.step,
            value: {
              min: data.temperature1,
              max: data.temperature3 ?? tempSettings1.value.max,
            },
          };

          var herbsSetting = {
            name: "Styrka",
            min: data.herbsAmountMin,
            max: data.herbsAmountMax,
            step: 0.1,
            value: data.herbsAmount,
          };

          this.setState(
            {
              selectedRecipe: data,
              orgSelectedRecipe: data,
              selectedNewHerbId: "",
              temp1: tempSettings1,
              temp2: tempSettings2,
              herbsStrength: herbsSetting,
              selectedTemperatureCount: data.temperatureCount.toString(),
            },
            () => {
              this.props.onKalle(
                data,
                tempSettings1.value.min,
                tempSettings1.value.max,
                tempSettings2.value.max,
                this.state.selectedTemperatureCount
              );
            }
          );
          return data;
        })
        .then((recipe) => {
          this.fetchWithSessionId("publicapi/herbs")
            .then((response) => {
              if (response.status === 401) {
                window.location.href = "/ginshoppen";
              }
              return response.json();
            })
            .then((data) => {
              let herbsFromApi = data.map((herb) => {
                let selected =
                  recipe.herbs.filter((h) => h.id === herb.id).length > 0;
                return {
                  value: herb.id,
                  suggestedValue: herb.suggestedValue,
                  category: herb.categoryName,
                  display: herb.name,
                  key: herb.id,
                  id: herb.id,
                  deletable: herb.deletable,
                  selected: selected,
                  sortingOrder: herb.group.sortingOrder,
                  sortOrder: herb.sortOrder,
                  group: herb.group,
                  description: herb.description,
                  wizardgroupid: herb.wizardGroupId,
                  wizardsortorder: herb.wizardSortOrder,
                  imageUrl: herb.imageUrl,
                  videoUrl: herb.videoUrl,
                };
              });

              let wizardGroups = this.sortWizardHerbs(herbsFromApi);

              this.setState(
                {
                  allHerbs: herbsFromApi,
                  wizardHerbs: wizardGroups,
                },
                () => {
                  this.props.onKalle(
                    this.state.selectedRecipe,
                    this.state.temp1.value.min,
                    this.state.temp1.value.max,
                    this.state.temp2.value.max,
                    this.state.selectedTemperatureCount
                  );
                }
              );
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  onHerbsStrengthChange = (data) => {
    let strength = {
      ...this.state.herbsStrength,
    };

    let change = {
      recipe: this.state.selectedRecipe,
      newStrength: data.value,
    };

    strength.value = data.value;

    const putMethod = {
      method: "PUT",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "X-Session-Id": cookies.get("recipe-session-id"),
      },
      body: JSON.stringify(change), // We send data in JSON format
    };

    const route = "publicapi/recipes/recalculate";

    fetch(route, putMethod)
      .then((response) => {
        if (response.status === 401) {
          window.location.href = "/ginshoppen";
        }
        return response.json();
      })
      .then((data) => {
        strength.value = data.herbsAmount;

        this.setState(
          {
            selectedRecipe: data,
            herbsStrength: strength,
          },
          () => {
            this.props.onKalle(
              this.state.selectedRecipe,
              this.state.temp1.value.min,
              this.state.temp1.value.max,
              this.state.temp2.value.max,
              this.state.selectedTemperatureCount
            );
          }
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  /// När man drar i en krydda
  onChange = (data) => {
    let h = this.state.selectedRecipe.herbs.filter(
      (herb) => herb.name === data.type
    )[0];

    var update = {
      recipe: this.state.selectedRecipe,
      herbChange: { id: h.id, newValue: data.value },
    };

    const putMethod = {
      method: "PUT",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "X-Session-Id": cookies.get("recipe-session-id"),
      },
      body: JSON.stringify(update), // We send data in JSON format
    };

    if (cookies.get("recipe-session-id")) {
      cookies.set("recipe-session-id", cookies.get("recipe-session-id"), {
        path: "/",
        maxAge: 1740,
        sameSite: "none",
        secure: true,
      });
    }

    const route = "publicapi/recipes/herbchange";

    fetch(route, putMethod)
      .then((response) => {
        if (response.status === 401) {
          window.location.href = "/ginshoppen";
        }
        return response.json();
      })
      .then((data) => {
        let strength = {
          ...this.state.herbsStrength,
        };

        strength.value = data.herbsAmount;

        this.setState(
          {
            selectedRecipe: data,
            herbsStrength: strength,
          },
          () => {
            this.props.onKalle(
              this.state.selectedRecipe,
              this.state.temp1.value.min,
              this.state.temp1.value.max,
              this.state.temp2.value.max,
              this.state.selectedTemperatureCount
            );
          }
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  onAddHerb(e, herb) {
    herb.selected = !herb.selected;
    var addHerb = herb.selected;

    var verb = addHerb ? "PUT" : "DELETE";

    const putMethod = {
      method: verb,
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "X-Session-Id": cookies.get("recipe-session-id"),
      },
      body: JSON.stringify(this.state.selectedRecipe),
    };

    if (cookies.get("recipe-session-id")) {
      cookies.set("recipe-session-id", cookies.get("recipe-session-id"), {
        path: "/",
        maxAge: 1740,
        sameSite: "none",
        secure: true,
      });
    }

    const route = "publicapi/recipes/" + herb.id;

    fetch(route, putMethod)
      .then((response) => {
        if (response.status === 401) {
          window.location.href = "/ginshoppen";
        }
        return response.json();
      })
      .then((data) => {
        const availableHerbs = this.state.allHerbs.filter((herb) => {
          return !data.herbs.some((v) => v.id === herb.id);
        });

        this.setState(
          {
            selectedRecipe: data,
            selectedRecipeCopy: data,
            availableHerbs: availableHerbs,
            selectedNewHerbId: "",
          },
          () => {
            this.props.onKalle(
              this.state.selectedRecipe,
              this.state.temp1.value.min,
              this.state.temp1.value.max,
              this.state.temp2.value.max,
              this.state.selectedTemperatureCount
            );
          }
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }

  resetRecipe = () => {
    // resets recipe to what it was when you last selected a botanical or recipe
    if (this.state.selectedRecipeCopy.herbs.length !== 0) {
      this.fetchWithSessionId("publicapi/herbs")
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          let herbsFromApi = data.map((herb) => {
            let selected =
              this.state.selectedRecipeCopy.herbs.filter(
                (h) => h.id === herb.id
              ).length > 0;
            return {
              value: herb.id,
              suggestedValue: herb.suggestedValue,
              category: herb.categoryName,
              display: herb.name,
              key: herb.id,
              id: herb.id,
              deletable: herb.deletable,
              selected: selected,
              sortingOrder: herb.group.sortingOrder,
              sortOrder: herb.sortOrder,
              group: herb.group,
              description: herb.description,
              wizardgroupid: herb.wizardGroupId,
              wizardsortorder: herb.wizardSortOrder,
              imageUrl: herb.imageUrl,
              videoUrl: herb.videoUrl,
            };
          });

          let wizardGroups = this.sortWizardHerbs(herbsFromApi);

          var tempSettings1 = {
            name: "Temp 1",
            min: 78.5,
            max: 96,
            step: 0.1,
            value: { min: data.temperature1, max: data.temperature2 },
          };
          var tempSettings2 = {
            name: "Temp 2",
            min: tempSettings1.min,
            max: tempSettings1.max,
            step: tempSettings1.step,
            value: {
              min: data.temperature1,
              max: data.temperature3 ?? tempSettings1.value.max,
            },
          };
          var herbsSetting = {
            name: "Styrka",
            min: data.herbsAmountMin,
            max: data.herbsAmountMax,
            step: 0.1,
            value: data.herbsAmount,
          };

          this.setState(
            {
              allHerbs: herbsFromApi,
              wizardHerbs: wizardGroups,
              selectedRecipe: this.state.selectedRecipeCopy,
              selectedNewHerbId: "",
              temp1: tempSettings1,
              temp2: tempSettings2,
              herbsStrength: herbsSetting,
              selectedTemperatureCount: 2,
            },
            () => {
              this.props.onKalle(
                this.state.selectedRecipeCopy,
                tempSettings1.value.min,
                tempSettings1.value.max,
                tempSettings2.value.max,
                this.state.selectedTemperatureCount
              );
            }
          );
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      return;
    }
  };

  onBottleChange = (event) => {
    const test =
      this.state.selectedRecipe.temperature3 !== null &&
        event.target.value === "8"
        ? "3"
        : "2";

    this.setState(
      {
        ...this.state,
        selectedBottlesOption: event.target.value,
        selectedTemperatureCount: test,
      },
      () => {
        this.props.onKalle(
          this.state.selectedRecipe,
          this.state.temp1.value.min,
          this.state.temp1.value.max,
          this.state.temp2.value.max,
          this.state.selectedTemperatureCount
        );
      }
    );
  };

  onselectedTemperatureCountChange = (event) => {
    this.setState(
      {
        selectedTemperatureCount: event.target.value,
      },
      () => {
        this.props.onKalle(
          this.state.selectedRecipe,
          this.state.temp1.value.min,
          this.state.temp1.value.max,
          this.state.temp2.value.max,
          this.state.selectedTemperatureCount
        );
      }
    );
  };

  handleSelectRecipe = (recipe) => {
    this.setState({
      selectRecipe: recipe.display,
      currentRecipeID: recipe.value,
    });

    sessionStorage.setItem("gin-recipeid", recipe.value);

    this.fetchWithSessionId(`publicapi/recipes/${recipe.value}`)
      .then((response) => {
        if (response.status === 401) {
          window.location.href = "/ginshoppen";
        }
        return response.json();
      })
      .then((data) => {
        var tempSettings1 = {
          name: "Temp 1",
          min: 78.5,
          max: 96,
          step: 0.1,
          value: { min: data.temperature1, max: data.temperature2 },
        };

        var tempSettings2 = {
          name: "Temp 2",
          min: tempSettings1.min,
          max: tempSettings1.max,
          step: tempSettings1.step,
          value: {
            min: data.temperature1,
            max: data.temperature3 ?? tempSettings1.value.max,
          },
        };

        var herbsSetting = {
          name: "Styrka",
          min: data.herbsAmountMin,
          max: data.herbsAmountMax,
          step: 0.1,
          value: data.herbsAmount,
        };

        this.setState(
          {
            selectedRecipe: data,
            selectedRecipeCopy: data,
            orgSelectedRecipe: data,
            selectedNewHerbId: "",
            temp1: tempSettings1,
            temp2: tempSettings2,
            herbsStrength: herbsSetting,
            selectedTemperatureCount: data.temperatureCount.toString(),
          },
          () => {
            this.props.onKalle(
              data,
              tempSettings1.value.min,
              tempSettings1.value.max,
              tempSettings2.value.max,
              this.state.selectedTemperatureCount
            );
          }
        );
        return data;
      })
      .then((recipe) => {
        this.fetchWithSessionId("publicapi/herbs")
          .then((response) => {
            if (response.status === 401) {
              window.location.href = "/ginshoppen";
            }
            return response.json();
          })
          .then((data) => {
            let herbsFromApi = data.map((herb) => {
              let selected =
                recipe.herbs.filter((h) => h.id === herb.id).length > 0;
              return {
                value: herb.id,
                suggestedValue: herb.suggestedValue,
                category: herb.categoryName,
                display: herb.name,
                key: herb.id,
                id: herb.id,
                deletable: herb.deletable,
                selected: selected,
                sortingOrder: herb.group.sortingOrder,
                sortOrder: herb.sortOrder,
                group: herb.group,
                description: herb.description,
                wizardgroupid: herb.wizardGroupId,
                wizardsortorder: herb.wizardSortOrder,
                imageUrl: herb.imageUrl,
                videoUrl: herb.videoUrl,
              };
            });

            let wizardGroups = this.sortWizardHerbs(herbsFromApi);

            this.setState(
              {
                allHerbs: herbsFromApi,
                wizardHerbs: wizardGroups,
              },
              () => {
                this.props.onKalle(
                  recipe,
                  this.state.temp1.value.min,
                  this.state.temp1.value.max,
                  this.state.temp2.value.max,
                  this.state.selectedTemperatureCount
                );
              }
            );
          })
          .catch((error) => {
            console.log(error);
          });
        return recipe
      })
      .then((recipe) => {
        const firstHerb = recipe.herbs[0]
        if (!firstHerb) return
        this.onChange({
          type: firstHerb.name,
          value: firstHerb.value,
        })
      })
      .catch((error) => {
        console.log(error);
      });
  };

  clearSelectedRecipe = () => {
    this.setState({ selectRecipe: "" });
  };

  handleSearch = (event) => {
    let value = event.target.value.toLowerCase();
    let result = [];
    result = this.state.recipes.filter((data) => {
      return (
        data.display
          .toLowerCase()
          .search(
            value.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&")
          ) !== -1
      );
    });
    let kundrecept = result;
    this.setState({ kundrecept: kundrecept });
  };

  updateCurrentRecipe = () => {
    const route = `publicapi/recipes/update/${this.state.currentRecipeID}`;
    const putMethod = {
      method: "PUT",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "X-Session-Id": cookies.get("recipe-session-id"),
      },
      body: {},
    };

    if (this.state.currentRecipeID) {
      fetch(route, putMethod)
        .then((response) => {
          if (response.status === 401) {
            window.location.href = "/ginshoppen";
          }
          return response.json();
        })
        .then((data) => {
          console.log(data);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      return;
    }
  };

  onTabChange = (event, newValue) => {
    this.props.history.push(`/ginshoppen/${tabNameToIndex[newValue]}`);
    this.setState({
      selectedTab: newValue,
    });
  };

  setDecState = (bool) => {
    this.setState({ sliderDecDisabled: bool });
  };

  setIncState = (bool) => {
    this.setState({ sliderIncDisabled: bool });
  };

  fetchCart = () => {
    const postMethod = {
      method: "GET",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "X-Session-Id": cookies.get("recipe-session-id"),
      },
    };
    fetch("publicapi/Cart", postMethod)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        /* console.log(data); */
        this.setState({ cartInfo: data });
      })
      .then((data) => {
        this.fetchCartPrice();
        this.handleCartItemDisplay();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  addToCart = (cartopen) => {
    const postMethod = {
      method: "POST",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "X-Session-Id": cookies.get("recipe-session-id"),
      },
      body: JSON.stringify(""),
    };

    fetch("publicapi/Cart/recipe", postMethod)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        this.setState({ cartInfo: data.items });
        this.fetchCart();
        return data;
      })
      .then((data) => {
        if (cartopen === false) return;
        this.setState({ cartOpen: true });
        setTimeout(() => {
          this.setState({ cartOpen: false });
        }, 3000);
      })
      .then((data) => {
        if (cookies.get("recipe-session-id")) {
          cookies.set("recipe-session-id", cookies.get("recipe-session-id"), {
            path: "/",
            maxAge: 1740,
            sameSite: "none",
            secure: true,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  addBottlesToCart = (id, amount) => {
    const postMethod = {
      method: "POST",
      headers: {
        "X-Session-Id": cookies.get("recipe-session-id"),
      },
    };

    fetch(`publicapi/Cart/bottle?id=${id}&count=${amount}`, postMethod)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        this.setState({ cartInfo: data.items });
        this.fetchCart();
        /* this.addToCart(false); */
        return data;
      })
      .then(() => {
        if (cookies.get("recipe-session-id")) {
          cookies.set("recipe-session-id", cookies.get("recipe-session-id"), {
            path: "/",
            maxAge: 1740,
            sameSite: "none",
            secure: true,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  fetchCartPrice = () => {
    var start = this.state.priceList.startFee;
    var bottleCost = 0;

    this.state.cartInfo.items &&
      this.state.cartInfo.items.forEach((item) => {
        if (item.type === 3) {
          bottleCost += item.amount;
        }
      });

    this.setState({ totalCost: start + bottleCost });
  };

  deleteCart = () => {
    fetch("publicapi/cart", {
      method: "DELETE",
      headers: {
        "X-Session-Id": cookies.get("recipe-session-id"),
        /* authHeader: JSON.parse(authInfo).key, */
      },
    })
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  openCart = () => {
    let cartItems = this.state.cartInfo.items;
    if (!cartItems) return;
    if (cartItems.some((e) => e.type === 0)) {
      this.setState({ cartOpen: !this.state.cartOpen });
      this.fetchCartPrice();
    }
  };

  addGiftCard = () => {
    this.setState({
      giftcardarray: [
        ...this.state.giftcardarray,
        { name: `Presentkort`, amount: 800 },
      ],
    });
  };

  removeGiftCard = (index) => {
    var giftcards = [...this.state.giftcardarray];
    if (index !== -1) {
      if (giftcards.length < 2) return;
      giftcards.splice(index, 1);
      this.setState({ giftcardarray: giftcards });
    }
  };

  handleGiftCardAmount = (index, func) => {
    let items = [...this.state.giftcardarray];
    let item = { ...items[index] };

    if (func === "increment") {
      item.amount += 100;
      items[index] = item;
      this.setState({ giftcardarray: items });
    } else if (func === "decrement") {
      if (item.amount === 100) return;
      item.amount -= 100;
      items[index] = item;
      this.setState({ giftcardarray: items });
    }
  };

  handleCartItemDisplay = () => {
    if (this.state.cartInfo.items) {
      this.state.cartInfo.items.forEach((item) => {
        if (item.name === "Recept") {
          this.setState({ cartamount: 1, updateRecipeBtn: true });
        }
      });
    } else {
      this.setState({ cartamount: 0 });
    }
  };

  checkRoute = (route) => {
    if (
      route === "/ginshoppen/wizard" ||
      route === "/ginshoppen/wizard_mangd" ||
      route === "/ginshoppen/wizard_typ"
    )
      return "none";
  };

  handleConsultation = () => {
    const storedValue = localStorage.getItem("consultation-wanted");

    if (storedValue === "true") {
      localStorage.setItem("consultation-wanted", "false");
      this.setState({
        consulationWanted: localStorage.getItem("consultation-wanted"),
      });
    } else {
      localStorage.setItem("consultation-wanted", "true");
      this.setState({
        consulationWanted: localStorage.getItem("consultation-wanted"),
      });
    }
  };

  render() {
    return (
      <div
        style={{
          marginTop: "8px",
        }}
      >
        <div
          className={styles.shop_nav}
          style={{
            display: this.checkRoute(window.location.pathname),
          }}
        >
          <div className={styles.nav_group}>
            <button
              style={{
                backgroundColor:
                  this.state.selectedTab !== 4 ? "var(--clr-cyan-5)" : "",
                color: this.state.selectedTab !== 4 ? "#fff" : "",
              }}
              onClick={(e) => this.onTabChange(e, 0)}
            >
              GinKonfiguratorn
            </button>

            {/* <button
              style={{
                backgroundColor:
                  this.state.selectedTab === 4 ? "var(--clr-cyan-5)" : "",
                color: this.state.selectedTab === 4 ? "#fff" : "",
              }}
              onClick={(e) => this.onTabChange(e, 4)}
            >
              Presentkort
            </button> */}
          </div>
          <div className={styles.cart_group}>
            <p
              className={styles.cart_name}
              style={{ fontWeight: "bold", fontSize: "20px" }}
            >
              Varukorg
            </p>
            <div className={styles.cart_icon} onClick={() => this.openCart()}>
              <span>
                <p>{this.state.cartamount > 0 ? this.state.cartamount : ""}</p>
              </span>
              <img src={cartImg} alt={"cart"} />
            </div>
            {this.state.cartOpen && (
              <div
                style={{
                  display: this.state.cartInfo.items
                    ? this.state.cartInfo.items.length > 0
                      ? ""
                      : "none"
                    : "",
                }}
                className={styles.cart}
              >
                <ul>
                  {this.state.cartInfo.items &&
                    this.state.cartInfo.items.map((item, index) =>
                      item.name !== "Recept" ? (
                        ""
                      ) : (
                        <li key={index}>
                          <div className={styles.cart_item}>
                            <img src={cartRecept} alt="recept" />
                            <p className={styles.item_name}>
                              {item.name === "Recept" ? "Min Gin" : item.name}
                            </p>
                            <p className={styles.item_cost}>
                              {this.state.totalCost} kr
                            </p>
                          </div>
                          <div className={styles.separator} />
                        </li>
                      )
                    )}
                </ul>
                <div className={styles.cart_btns}>
                  <p>
                    Totalsumma: <mark>{this.state.totalCost} kr</mark>
                  </p>
                  <Link to="/varukorg">till kassan</Link>
                </div>
              </div>
            )}
          </div>
        </div>
        <div>
          {this.state.selectedTab !== 6 && (
            <StyledTabs
              value={this.state.selectedTab}
              onChange={this.onTabChange}
              style={{
                display:
                  this.state.selectedTab === 7 || this.state.selectedTab === 8
                    ? "none"
                    : "",
              }}
            >
              <StyledTab label="Recept" />
              <StyledTab label="Botanicals" />
              <StyledTab label="Mängd Botanicals" />
              <StyledTab label="Typ och Styrka" />
              <StyledTab style={{ display: "none" }} label="Presentkort" />
              <StyledTab style={{ display: "none" }} label="Varukorg" />
              <StyledTab style={{ display: "none" }} label="Wizard" />
              <StyledTab style={{ display: "none" }} label="wizard_mangd" />
              <StyledTab style={{ display: "none" }} label="wizard_typ" />
              <StyledTab style={{ display: "none" }} label="Kassa" />
              <StyledTab
                style={{ display: "none" }}
                label="Tack för din beställning"
              />
            </StyledTabs>
          )}

          {this.state.selectedTab !== 7 && (
            <>
              {this.state.isAdmin ? (
                <button
                  className="customBtn2"
                  onClick={() => this.updateCurrentRecipe()}
                  style={{ marginTop: "32px" }}
                >
                  <img src={nollstall} alt="uppdatera" />
                  Uppdatera recept
                </button>
              ) : (
                ""
              )}

              <div
                className={styles.main_container}
                style={{ display: this.state.selectedTab !== 0 ? "none" : "" }}
              >
                <h1 className={styles.ctitle}>
                  {this.state.contentfulRecept.title}
                </h1>
                <p className={styles.cdesc} dangerouslySetInnerHTML={{
                  __html: marked(
                    this.state.contentfulRecept.description !== undefined || null
                      ? this.state.contentfulRecept.description
                      : ""
                  ),
                }} />
                <div className={`${styles.container} ${styles.box}`}>
                  <div className={styles.recipes_container}>
                    <h4>Recept</h4>
                    <RecipePagination
                      recipes={this.state.grundrecept}
                      handleSelectRecipe={this.handleSelectRecipe}
                      fetchWithAuthHeader={this.fetchWithSessionId}
                      selectRecipe={this.state.selectRecipe}
                      handleSearch={this.handleSearch}
                      disabledSearch={true}
                      title={"Ginkällans grundrecept"}
                      data={this.props.data}
                    />
                    <RecipePagination
                      recipes={this.state.ggsrecept}
                      handleSelectRecipe={this.handleSelectRecipe}
                      fetchWithAuthHeader={this.fetchWithSessionId}
                      selectRecipe={this.state.selectRecipe}
                      handleSearch={this.handleSearch}
                      disabledSearch={true}
                      title={"Göteborgs Ginsällskap"}
                      data={this.props.data}
                    />
                    <RecipePagination
                      recipes={this.state.kundrecept}
                      handleSelectRecipe={this.handleSelectRecipe}
                      fetchWithAuthHeader={this.fetchWithSessionId}
                      selectRecipe={this.state.selectRecipe}
                      handleSearch={this.handleSearch}
                      title={"Övriga recept"}
                      data={this.props.data}
                    />
                  </div>
                </div>
                <div className={styles.overview}>
                  <Overview
                    name={"Gin recept"}
                    recipe={this.state.selectedRecipe.herbs}
                    changeTab={this.onTabChange}
                    changeName={"Fortsätt till Välj Botanicals"}
                    tabNr={1}
                    data={this.props.data}
                    restartbtn={() => this.restart()}
                    cartInfo={this.state.cartInfo}
                    consultation={this.handleConsultation}
                    consultationState={this.state.consulationWanted}
                  />
                </div>
              </div>

              <div
                className={styles.main_container}
                style={{ display: this.state.selectedTab !== 1 ? "none" : "" }}
              >
                <h1 className={styles.ctitle}>
                  {this.state.contentfulBotanicals.title}
                </h1>
                <p className={styles.cdesc} dangerouslySetInnerHTML={{
                  __html: marked(
                    this.state.contentfulBotanicals.description !== undefined || null
                      ? this.state.contentfulBotanicals.description
                      : ""
                  ),
                }} />
                <div className={`${styles.container} ${styles.box}`}>
                  <h4 className={styles.heading}>Val av botanicals</h4>
                  <div className={styles.botanicals}>
                    {this.showHerbs(this.state.allHerbs)}
                  </div>
                </div>
                <div className={styles.overview}>
                  <Overview
                    name={"Gin recept"}
                    orgRecipe={this.state.orgSelectedRecipe.herbs}
                    recipe={this.state.selectedRecipe.herbs}
                    changeTab={this.onTabChange}
                    changeName={"Fortsätt till Mängd Botanicals"}
                    tabNr={2}
                    data={this.props.data}
                    resetSelectedbtn={() => this.resetSelectedRecipe()}
                    resetbtn={() => this.resetRecipe()}
                    cartInfo={this.state.cartInfo}
                    consultation={this.handleConsultation}
                    consultationState={this.state.consulationWanted}
                  />
                </div>
              </div>

              <div
                className={styles.main_container}
                style={{ display: this.state.selectedTab !== 2 ? "none" : "" }}
              >
                <h1 className={styles.ctitle}>
                  {this.state.contentfulMangd.title}
                </h1>
                <p className={styles.cdesc} dangerouslySetInnerHTML={{
                  __html: marked(
                    this.state.contentfulMangd.description !== undefined || null
                      ? this.state.contentfulMangd.description
                      : ""
                  ),
                }} />
                <div className={`${styles.container} ${styles.box}`}>
                  <h4 className={styles.heading}>Mängd av Botanicals</h4>
                  <>
                    {this.state.selectedRecipe.herbs.map((herb) => (
                      <Slider
                        key={herb.name}
                        data={herb}
                        min={herb.min}
                        max={herb.max}
                        value={Math.round(herb.value * 10) / 10}
                        label={(value) => `${value}g`}
                        onChangeFunc={this.onChange}
                        decDisabled={
                          this.state.herbsStrength.value - herb.step <
                            this.state.herbsStrength.min ||
                            herb.value <= herb.min
                            ? true
                            : false
                        }
                        incDisabled={
                          this.state.herbsStrength.value + herb.step >
                            this.state.herbsStrength.max ||
                            herb.value >= herb.max
                            ? true
                            : false
                        }
                      />
                    ))}
                    <div className={styles.container_btn_group_main}>
                      <button onClick={() => this.resetRatio()}>
                        Rekommenderad Mängd
                      </button>
                      {this.state.selectedRecipeCopy.herbs.length !== 0 ? (
                        <button onClick={() => this.resetRecipe()}>
                          Återställ mängd
                        </button>
                      ) : (
                        ""
                      )}
                    </div>
                  </>
                </div>
                <div className={styles.overview}>
                  <Overview
                    name={"Gin recept"}
                    /* orgRecipe={this.state.orgSelectedRecipe.herbs} */
                    recipe={this.state.selectedRecipe.herbs}
                    changeTab={this.onTabChange}
                    changeName={"Fortsätt till Typ och Styrka"}
                    tabNr={3}
                    total={true}
                    data={this.props.data}
                    cartInfo={this.state.cartInfo}
                    consultation={this.handleConsultation}
                    consultationState={this.state.consulationWanted}
                  />
                </div>
              </div>

              <div
                className={`${styles.main_container} ${styles.bottle_conf_container}`}
                style={{ display: this.state.selectedTab !== 3 ? "none" : "" }}
              >
                <h1 className={styles.ctitle}>
                  {this.state.contentfulTypOchStyrka.title}
                </h1>
                <p className={styles.cdesc} dangerouslySetInnerHTML={{
                  __html: marked(
                    this.state.contentfulTypOchStyrka.description !== undefined || null
                      ? this.state.contentfulTypOchStyrka.description
                      : ""
                  ),
                }} />

                <div className={`${styles.container} ${styles.box}`}>
                  <BottleConfigurator
                    data={this.props.data}
                    onKalle={this.props.onChangeBottleConfig}
                    fetchWithSessionId={this.fetchWithSessionId}
                    fetchCart={this.fetchCart}
                    addBottlesToCart={this.addBottlesToCart}
                    cartInfo={this.state.cartInfo}
                  />
                </div>
                <div className={styles.overview}>
                  <Overview
                    name={"Gin recept"}
                    recipe={this.state.selectedRecipe.herbs}
                    summary={true}
                    data={this.props.data}
                    addtocart={true}
                    addtocartfunc={() => this.addToCart()}
                    updateRecipeBtn={this.state.updateRecipeBtn}
                    cartamount={this.state.cartamount}
                    tabchange={this.state.selectedTab}
                    cartInfo={this.state.cartInfo}
                    consultation={this.handleConsultation}
                    consultationState={this.state.consulationWanted}
                  />
                </div>
              </div>

              <div
                style={{ display: this.state.selectedTab !== 4 ? "none" : "" }}
              >
                <div className={styles.main_container}>
                  <div className={`${styles.container} ${styles.box}`}>
                    <h4 className={styles.heading}>
                      Presentkort på valfritt belopp
                    </h4>
                    {this.state.giftcardarray.map((gc, index) => (
                      <GiftCard
                        key={index}
                        placeholder={trash}
                        namn={`${gc.name} ${index + 1}`}
                        amount={gc.amount}
                        remove={() => this.removeGiftCard(index)}
                        onIncrease={() =>
                          this.handleGiftCardAmount(index, "increment")
                        }
                        onDecrease={() =>
                          this.handleGiftCardAmount(index, "decrement")
                        }
                      />
                    ))}
                    <button onClick={() => this.addGiftCard()}>
                      Lägg till Presentkort
                    </button>
                  </div>
                  <div className={styles.overview}>
                    <div className={styles.overview_box}>
                      <div className={styles.overview_header}>
                        <h4>Presentkort - Översikt</h4>
                      </div>
                      <ul>
                        {this.state.giftcardarray.map((gc, index) => (
                          <React.Fragment key={index}>
                            <li>
                              <p>
                                {gc.name} {index + 1}
                              </p>
                              <p>{gc.amount}:-</p>
                            </li>
                            <div className={styles.separator} />
                          </React.Fragment>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
                {/* <GiftCardCalc /> */}
              </div>
            </>
          )}

          {this.state.selectedTab === 5 && (
            <div>
              <Confirmation
                data={this.state}
                onConfirmationLoaded={this.props.onConfirmationLoaded}
                fetchCart={() => this.fetchCart()}
                loadComponent={() => this.loadComponent()}
                loadRecipes={() => this.loadRecipes()}
                restart={() => this.restart()}
                clearSelectedRecipe={() => this.clearSelectedRecipe()}
              />
            </div>
          )}

          {this.state.selectedTab === 6 && (
            <div>
              <div className={`${styles.wizard_container}`} id={"wizard"}>
                {this.showWizardHerbs(this.state.wizardHerbs)}
              </div>

              <div style={{ height: "1px", marginBottom: "800px" }} />
            </div>
          )}

          {this.state.selectedTab === 7 && (
            <div className={styles.wizardtwo}>
              <a href={"/ginshoppen/wizard"} className={styles.stepone}>
                <img alt="previous page" />
                <h4>Botanicals</h4>
              </a>
              {this.state.selectedRecipe.herbs.map((herb) => (
                <WizardConfMangd
                  key={herb.name}
                  data={herb}
                  min={herb.min}
                  max={herb.max}
                  value={Math.round(herb.value * 10) / 10}
                  onChangeFunc={this.onChange}
                  decDisabled={
                    this.state.herbsStrength.value - herb.step <
                      this.state.herbsStrength.min || herb.value <= herb.min
                      ? true
                      : false
                  }
                  incDisabled={
                    this.state.herbsStrength.value + herb.step >
                      this.state.herbsStrength.max || herb.value >= herb.max
                      ? true
                      : false
                  }
                />
              ))}
              <div className={styles.wizardtwo}>
                <a href={"/ginshoppen/wizard_typ"} className={styles.reversed}>
                  <h4>Typ och Styrka</h4>
                  <img className={styles.reversed} alt="next page" />
                </a>
              </div>
            </div>
          )}

          {this.state.selectedTab === 8 && (
            <div className={styles.wizardtwo}>
              <a href={"/ginshoppen/wizard_mangd"} className={styles.stepone}>
                <h4>Mängd</h4>
                <img alt="previous page" />
              </a>

              <div className={styles.wizardtyp}>
                <div className={styles.typ_container}>
                  <BottleConfigurator
                    data={this.props.data}
                    onKalle={this.props.onChangeBottleConfig}
                    fetchWithSessionId={this.fetchWithSessionId}
                    fetchCart={this.fetchCart}
                    addBottlesToCart={this.addBottlesToCart}
                    cartInfo={this.state.cartInfo}
                  />
                </div>
                <div className={styles.overview}>
                  <Overview
                    name={"Gin recept"}
                    recipe={this.state.selectedRecipe.herbs}
                    summary={true}
                    data={this.props.data}
                    addtocart={true}
                    addtocartfunc={() => this.addToCart()}
                    updateRecipeBtn={this.state.updateRecipeBtn}
                    cartamount={this.state.cartamount}
                    tabchange={this.state.selectedTab}
                    cartInfo={this.state.cartInfo}
                    consultation={this.handleConsultation}
                    consultationState={this.state.consulationWanted}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  showHerbs(herbs) {
    let group = herbs.reduce((r, a) => {
      r[a.category] = [...(r[a.category] || []), a];
      return r;
    }, {});

    let array = [];

    Object.keys(group).forEach((key) => {
      array.push(
        <HerbPagination
          key={key}
          header={key}
          herbs={group[key]}
          sortingOrder={group[key][0].sortingOrder}
          onAddHerb={this.onAddHerb}
        />
      );
    });

    var sortedArray = array.slice().sort(function (a, b) {
      return a.props.sortingOrder - b.props.sortingOrder;
    });

    sortedArray.slice().forEach((category) => {
      category.props.herbs.sort(
        (a, b) => (a.sortOrder ?? Infinity) - (b.sortOrder ?? Infinity)
      );
    });

    return sortedArray;
  }

  showWizardHerbs(group) {
    let array = [];

    Object.keys(group).forEach((key) => {
      array.push(
        <WizardConf
          key={parseInt(key)}
          allHerbs={this.state.wizardHerbs}
          herbs={group[key]}
          groups={this.state.wizardGroups}
          id={parseInt(key)}
          onAddHerb={this.onAddHerb}
        />
      );
    });

    return array;
  }
}

export default RecipeBuilder;
