import React, { useState, useEffect } from "react";
import styles from "./page_styles.module.scss";
import globalStyles from "../global.module.scss";
import Navbar from "../components/Navbar/Navbar";
import client from "../services/contentful.js";
import { useHistory } from "react-router-dom";
import { marked } from "marked";

const GinShoppen = () => {
  const [isLoading, setisLoading] = useState(true);
  const [shoppenBanner, setShoppenBanner] = useState("");
  const [easyBg, setEasyBg] = useState("");
  const [advancedBg, setAdvancedBg] = useState("");
  const [content, setContent] = useState([]);

  const history = useHistory();

  const navigateToEasy = () => {
    history.push("/ginshoppen/wizard");
  };
  const navigateToAdvanced = () => {
    history.push("/ginshoppen/recepten");
  };

  const getShoppenContent = () => {
    client.getEntry("WPCNzX62bOo1fcCwzEpZq").then((entry) => {
      setContent(entry.fields);
      setShoppenBanner(entry.fields.banner.fields.file.url);
      setEasyBg(entry.fields.bildEnkel.fields.file.url);
      setAdvancedBg(entry.fields.bildAvancerad.fields.file.url);
    });

    setisLoading(false);
  };

  useEffect(() => {
    getShoppenContent();
  }, []);

  return (
    <>
      {isLoading ? (
        <></>
      ) : (
        <div>
          <Navbar />
          <div
            className={styles.shoppenbanner}
            style={{ backgroundImage: `URL(https:${shoppenBanner})` }}
          >
            <h1 className={globalStyles.max_width}>{content.title}</h1>
          </div>

          <div className={styles.ginshoppen}>
            <div style={{ padding: "0 20px" }}>
              <p
                dangerouslySetInnerHTML={{
                  __html: marked(
                    content.beskrivning !== undefined || null
                      ? content.beskrivning
                      : ""
                  ),
                }}
              />
            </div>

            <div className={styles.ginconf}>
              <div className={styles.title}>
                <h1>Gör din egen Gin</h1>
              </div>
              <div className={styles.cont}>
                <div
                  className={styles.btn}
                  style={{
                    backgroundImage: `linear-gradient(0deg, #00000088 70%, #ffffff44 100%), URL(https:${easyBg})`,
                  }}
                  onClick={() => navigateToEasy()}
                >
                  <h4>{content.titelEnkel}</h4>
                  <p>{content.beskrivningEnkel}</p>
                </div>
                <div
                  className={styles.btn}
                  style={{
                    backgroundImage: `linear-gradient(0deg, #00000088 70%, #ffffff44 100%), URL(https:${advancedBg})`,
                  }}
                  onClick={() => navigateToAdvanced()}
                >
                  <h4>{content.titelAvancerad}</h4>
                  <p>{content.beskrivningAvancerad}</p>
                </div>
              </div>

              <div className={styles.extraInfo}>
                <p
                  dangerouslySetInnerHTML={{
                    __html: marked(
                      content.extraInfo !== undefined || null
                        ? content.extraInfo
                        : ""
                    ),
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default GinShoppen;
