import React, { useState, useEffect } from "react";
import styles from "./VideoPagination.module.scss";
import useWindowDimensions from "../../CustomHooks/useWindowDimensions";
import next_arrow from "../../../assets/icons/next_arrow.svg";
import VideoCard from "../../Cards/VideoCard/VideoCard";

const VideoPagination = ({ videos }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [videosPerPage, setVideosPerPage] = useState(5);

  const { width } = useWindowDimensions();

  const indexOfLastRecipe = currentPage * videosPerPage;
  const indexOfFirstRecipe = indexOfLastRecipe - videosPerPage;
  const currentItems = videos.slice(indexOfFirstRecipe, indexOfLastRecipe);

  const maxPages = videos.length / videosPerPage;

  useEffect(() => {
    setCurrentPage(1);
  }, [videos]);

  useEffect(() => {
    if (width <= 705) {
      setVideosPerPage(1);
    } else if (width <= 1024) {
      setVideosPerPage(2);
    } else if (width <= 1340) {
      setVideosPerPage(3);
    } else {
      setVideosPerPage(4);
    }
  }, [width]);

  const handlePrevbtn = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleNextbtn = () => {
    setCurrentPage(currentPage + 1);
  };

  return (
    <div
      className={styles.video_container}
      style={{ display: currentItems.length === 0 ? "none" : "" }}
    >
      <button
        className={`${styles.previousBtn} ${styles.videoBtn}`}
        style={{ display: currentPage === 1 ? "none" : "" }}
        onClick={handlePrevbtn}
        disabled={currentPage === 1 ? true : false}
      >
        <img src={next_arrow} alt="previous" />
      </button>

      <ul>
        {currentItems.map((video, index) => (
          <li key={index}>
            <VideoCard
              title={video.title}
              videoUrl={video.url}
              thumbnail={video.thumbnail && video.thumbnail.fields.file.url}
              hover={video.hover}
            />
          </li>
        ))}
      </ul>

      <button
        className={`${styles.nextBtn} ${styles.videoBtn}`}
        style={{ display: currentPage >= maxPages ? "none" : "" }}
        onClick={handleNextbtn}
        disabled={currentPage >= maxPages ? true : false}
      >
        <img src={next_arrow} alt="next" />
      </button>
    </div>
  );
};

export default VideoPagination;
