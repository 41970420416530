import React, { useState, useEffect } from "react";
import VideoModal from "../../Modals/VideoModal/VideoModal";
import styles from "./VideoCard.module.scss";

const VideoCard = ({ title, thumbnail, videoUrl, hover }) => {
  const [videoId, setVideoId] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (videoUrl) {
      var parts = videoUrl.split("=");
      var answer = parts[parts.length - 1];
      setVideoId(answer);
    } else {
      setVideoId("");
    }
  }, []);

  return (
    <>
      <div
        className={styles.video_card}
        style={{ backgroundImage: thumbnail ? `URL(${thumbnail})` : "" }}
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className={`${styles.overlay} ${hover && styles.overlay2}`}>
          {hover && <h4 className={styles.hover}>{hover}</h4>}

          <h3>{title}</h3>
        </div>
      </div>
      <VideoModal isOpen={isOpen} setIsOpen={setIsOpen} videoId={videoId} />
    </>
  );
};

export default VideoCard;
