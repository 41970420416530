import React from "react";
import styles from "./Layout.module.scss";

const Layout = ({ children }) => {
  return (
    <div className={styles.layout}>
      <main
        style={{
          height:
            window.location.pathname === "/ginshoppen/wizard" ? "100vh" : "",
        }}
      >
        {children}
      </main>
    </div>
  );
};

export default Layout;
