import React, { useState, useEffect } from "react";
import styles from "./Footer.module.scss";
import client from "../../services/contentful.js";
import { marked } from "marked";

const Footer = () => {
  const [isLoading, setisLoading] = useState(true);
  const [footerContent, setFooterContent] = useState([]);
  const [emailIkon, setEmailIkon] = useState("");
  const [telefonIkon, setTelefonIkon] = useState("");
  const [platsIkon, setPlatsIkon] = useState("");

  useEffect(() => {
    const getFooterContent = () => {
      client.getEntry("5iviiJGukZ3rdgMXX1oLyW").then((entry) => {
        setFooterContent(entry.fields);
        setEmailIkon(entry.fields.emailIkon.fields.file.url);
        setTelefonIkon(entry.fields.telefonIkon.fields.file.url);
        setPlatsIkon(entry.fields.platsIkon.fields.file.url);
      });

      setisLoading(false);
    };

    getFooterContent();
  }, []);

  return (
    <>
      {isLoading ? (
        ""
      ) : (
        <div className={styles.container}>
          <div className={styles.info}>
            <h1>{footerContent.titel}</h1>
            <div
              className={styles.footerbeskrivning}
              dangerouslySetInnerHTML={{
                __html: marked(footerContent.beskrivning !== undefined || null ? footerContent.beskrivning : ""),
              }}
            />
          </div>
          <ul className={styles.contact}>
            <li>
              <img src={`https:${emailIkon}`} alt="email" />
              <p>{footerContent.email}</p>
            </li>
            <li>
              <img src={`https:${telefonIkon}`} alt="phone" />
              <p>{footerContent.telefon}</p>
            </li>
            <li>
              <img src={`https:${platsIkon}`} alt="location" />
              <p>{footerContent.plats}</p>
            </li>
          </ul>
        </div>
      )}
    </>
  );
};

export default Footer;
