import React, { useState, useEffect } from "react";
import VideoModal from "../Modals/VideoModal/VideoModal";

const VideoButton = ({ videoUrl, children }) => {
  const [videoId, setVideoId] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (videoUrl) {
      var parts = videoUrl.split("=");
      var answer = parts[parts.length - 1];
      setVideoId(answer);
    } else {
      setVideoId("");
    }
  }, [videoUrl]);

  const openVideo = () => {
    if (videoUrl.length !== 0) setIsOpen(!isOpen);
  };

  return (
    <div onClick={() => videoUrl && openVideo()}>
      {children}
      {videoUrl && (
        <VideoModal isOpen={isOpen} setIsOpen={setIsOpen} videoId={videoId} />
      )}
    </div>
  );
};

export default VideoButton;
