import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar/Navbar";
import styles from './page_styles.module.scss';
import globalStyles from "../global.module.scss";

import { marked } from "marked";
import client from "../services/contentful.js";

const Ginspiration = () => {
  const [isLoading, setisLoading] = useState(true);
  const [guideBanner, setGuideBanner] = useState("");
  const [content, setContent] = useState([]);

  useEffect(() => {
    const getBannerContent = () => {
      client.getEntry("DPxGleXPUv52tdfSDqUMw").then((entry) => {
        setContent(entry.fields);
        setGuideBanner(entry.fields.banner.fields.file.url);
      });

      setisLoading(false);
    };

    getBannerContent();
  }, []);

  return (
    <>
      {isLoading ? (
        <></>
      ) : (
        <div className={styles.container}>
          <Navbar />
          <div className={styles.banner} style={{ backgroundImage: `URL(https:${guideBanner})` }}>
            {content.title}
          </div>
          <div
            className={`${styles.content} ${globalStyles.max_width}`}
            dangerouslySetInnerHTML={{
              __html: marked(content.beskrivning !== undefined || null ? content.beskrivning : ""),
            }}
          />
          {/* <div className={`${styles.link}`}>
            <a href="https://www.google.com">
              Till Systembolaget
            </a>
          </div> */}
        </div>
      )}
    </>
  );
};

export default Ginspiration;
