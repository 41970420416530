import React, { useEffect, useState } from "react";
import styles from "./Confirmation.module.scss";
import { marked } from "marked";
import client from "../services/contentful.js";
import Cookies from "universal-cookie";

const Confirmation = (props) => {
  const [isLoading, setisLoading] = useState(true);
  const [content, setContent] = useState([]);
  const [orderReferense, setOrderReferense] = useState();
  const [authInfo] = useState(sessionStorage.getItem("userData"));

  const cookies = new Cookies();

  const clearCart = () => {
    const postMethod = {
      method: "GET",
      headers: {
        "X-Session-Id": cookies.get("recipe-session-id"),
      },
    };

    fetch("publicapi/Cart", postMethod)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        data.items.forEach((element) => {
          fetch(`publicapi/cart/${element.id}`, {
            method: "DELETE",
            headers: {
              "X-Session-Id": cookies.get("recipe-session-id"),
            },
          })
            .then((response) => {
              return response.json();
            })
            .catch((error) => {
              console.log(error);
            });
        });
      })
      .catch((error) => {
        console.log(error);
      });

    fetch("publicapi/cart", {
      method: "DELETE",
      headers: {
        "X-Session-Id": cookies.get("recipe-session-id"),
      },
    })
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    const getContent = () => {
      client.getEntry("66iMurPigqfTny2JzMC4y0").then((entry) => {
        setContent(entry.fields);
      });

      setisLoading(false);
    };

    getContent();

    if (!authInfo) return; // om man inte är inloggad
    clearCart();
    props.clearSelectedRecipe();
    setTimeout(() => {
      props.loadRecipes();
    }, 4000);

    setOrderReferense(JSON.parse(localStorage.getItem("orderRef")));

    fetch("publicapi/gincooker/clear", {
      method: "PUT",
      headers: {
        "X-Session-Id": cookies.get("recipe-session-id"),
      },
    })
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        console.log(error);
      });

    if (props.onConfirmationLoaded !== true) {
      props.onConfirmationLoaded(true);
    }
  }, []);

  useEffect(() => {
    authInfo &&
      fetch(`api/Klarna/callback?klarna_order_id=${orderReferense}`, {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          "X-Session-Id": cookies.get("recipe-session-id"),
          authHeader: JSON.parse(authInfo).key,
        },
        body: JSON.stringify(""),
      })
        .then((data) => {
          localStorage.removeItem("orderRef");
          sessionStorage.removeItem("gin-recipeid");
          cookies.remove("recipe-session-id", { path: "/" });
          fetch("publicapi/recipes/initial", {
            method: "GET",
            "X-Session-Id": " ",
          })
            .then((response) => {
              return response.json();
            })
            .then((data) => {
              cookies.set("recipe-session-id", data.sessionId, {
                path: "/",
                maxAge: 1740,
                sameSite: "none",
                secure: true,
              });
              return data;
            })
            .then((data) => {
              props.restart();
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((error) => {
          console.log(error);
        });
  }, [orderReferense]);

  return (
    <>
      {isLoading ? (
        <></>
      ) : (
        <div
          className={styles.confirmation_container}
          style={{ display: props.hidden ? "none" : "" }}
        >
          <h1>{content.titel}</h1>
          <div
            dangerouslySetInnerHTML={{
              __html: marked(
                content.beskrivning !== undefined || null
                  ? content.beskrivning
                  : ""
              ),
            }}
          />
        </div>
      )}
    </>
  );
};

export default Confirmation;
