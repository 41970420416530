import React, { useState, useEffect } from "react";
import styles from "./ShoppingCart.module.scss";
import Navbar from "./Navbar/Navbar.jsx";
import globalStyles from "../global.module.scss";
import { Link } from "react-router-dom";
import Cookies from "universal-cookie";

import bannerBild from "../assets/images/10_ginsmalltopimg_1920x192.jpg";
import trash from "../assets/icons/trash-alt.svg";
import Footer from "./Footer/Footer";

const cookies = new Cookies();

const ShoppingCart = () => {
  const [discountCode, setDiscountCode] = useState("");
  const [recipeHerbs, setRecipeHerbs] = useState([]);
  const [recipeItem, setRecipeItem] = useState([]);
  const [cartItems, setCartItems] = useState([]);
  const [discount, setDiscount] = useState(0);
  const [bottles, setBottles] = useState([]);
  const [prices, setPrices] = useState([]);

  useEffect(() => {
    fetchPriceList();
    fetchCartItems();
  }, []);

  useEffect(() => {
    bottles.sort((a, b) => a.bottle?.bottleId - b.bottle?.bottleId);
  }, [bottles]);

  const getSystemCompanyFee = () => {
    let totalSCfee = 0;
    bottles &&
      bottles.forEach((bottle) => {
        totalSCfee += bottle.count * bottle.bottle.systemCompanyPrice;
      });

    return Math.round(totalSCfee * 10) / 10;
  };

  const getAlcoTax = () => {
    let alcoTax = prices.alcoTax;
    let totalAlcoTax = 0;
    bottles &&
      bottles.forEach((bottle) => {
        totalAlcoTax +=
          bottle.count * 0.5 * Number(`0.${bottle.bottle.alcVol}`) * alcoTax;
      });

    return totalAlcoTax.toFixed(2);
  };

  const fetchCartItems = () => {
    const postMethod = {
      method: "GET",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "X-Session-Id": cookies.get("recipe-session-id"),
      },
    };
    fetch("publicapi/Cart", postMethod)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setCartItems(data.items);
        data.items.forEach((item) => {
          if (item.type === 0) {
            setRecipeItem(item);
            setRecipeHerbs(item.recipe.herbs);
          } else if (item.type === 2) {
            setDiscount(item);
          } else if (item.type === 3) {
            if (!bottles.includes(item)) {
              setBottles((bottles) => [...bottles, item]);
            }
          }
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const removeDiscount = (id) => {
    const deleteMethod = {
      method: "DELETE",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "X-Session-Id": cookies.get("recipe-session-id"),
      },
    };
    fetch(`publicapi/Cart/${id}`, deleteMethod)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setDiscount({});
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchPriceList = () => {
    const postMethod = {
      method: "GET",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "X-Session-Id": cookies.get("recipe-session-id"),
      },
    };
    fetch("publicapi/settings/pricelist", postMethod)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setPrices(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const calcMoms = (price) => {
    var exmoms = price / 1.25;
    var moms = price - exmoms;
    var momsRounded = moms.toFixed(2);

    return momsRounded;
  };

  const handlePostFee = () => {
    let bottleAmnt = 0;
    let postFee = 0;

    bottles.forEach((bottle) => {
      bottleAmnt += bottle.count;
    });

    if (bottleAmnt >= 8) {
      postFee = prices.postFee4;
    } else if (bottleAmnt >= 6) {
      postFee = prices.postFee3;
    } else if (bottleAmnt >= 4) {
      postFee = prices.postFee2;
    } else {
      postFee = prices.postFee1;
    }

    return postFee;
  };

  const sumToPay = () => {
    let price = prices.startFee;

    cartItems.forEach((item) => {
      if (item.type === 3) {
        price += item.count * item.bottle.price;
      }
    });

    return price;
  };

  const totalToPay = () => {
    let total = sumToPay() + handlePostFee();

    if (discount.amount > 0) {
      return total - discount.amount;
    } else {
      return total;
    }
  };

  const handleDiscount = (e) => {
    e.preventDefault();

    const postMethod = {
      method: "POST",
      headers: {
        "X-Session-Id": cookies.get("recipe-session-id"),
      },
      body: JSON.stringify(discountCode),
    };

    fetch(`publicapi/Cart/discount?id=${discountCode}`, postMethod)
      .then((response) => {
        if (response.status === 404) {
          setDiscountCode("");
          return;
        }
        return response.json();
      })
      .then((data) => {
        if (data === undefined) return;
        data.items.forEach((item) => {
          setDiscount(item);
        });
      })
      .then((result) => {
        setDiscountCode("");
      })
      .catch((error) => {
        setDiscountCode("");
      });
  };

  const handleDiscountInput = (e) => {
    setDiscountCode(e.target.value);
  };

  const handleKassaBtn = () => {
    if (cartItems.length === 0) {
      return <Link to="/ginshoppen/typ_och_styrka">Varukorgen är tom</Link>;
    } else if (bottles.length === 0) {
      return (
        <Link to="/ginshoppen/typ_och_styrka">Lägg till minst 2 flaskor</Link>
      );
    } else if (recipeItem.length === 0) {
      return (
        <Link to="/ginshoppen/typ_och_styrka">
          Varukorgen saknar ett recept
        </Link>
      );
    } else {
      return <Link to="/kassa">Gå till Kassan</Link>;
    }
  };

  return (
    <>
      <Navbar />
      <div
        id="tabelLabel"
        className={styles.title}
        style={{
          backgroundImage: `URL(${bannerBild})`,
          backgroundSize: "cover",
          backgroundPosition: "50% 50%",
          textAlign: "left",
        }}
      >
        <h1
          style={{
            maxWidth: "1440px",
            margin: "0 auto",
            fontWeight: "bold",
            fontSize: "52px",
            color: "var(--clr-gray-8)",
          }}
        >
          Varukorg
        </h1>
      </div>
      <div className={`${styles.shopping_container} ${globalStyles.max_width}`}>
        {bottles && (
          <div className={styles.product}>
            <div className={styles.econtainer} key={recipeItem.id}>
              <div>
                <details open>
                  <summary>
                    <p>{recipeItem.name}</p>
                    <p className={styles.price}>{sumToPay()} kr</p>
                  </summary>

                  <div className={styles.listcontainer}>
                    <ul className={styles.herblist}>
                      <li className={styles.bold}>
                        <p>Botanicals</p> <p>Mängd</p>
                      </li>
                      {recipeHerbs &&
                        recipeHerbs.map((herb, index) => (
                          <li key={index}>
                            <p>{herb.name}</p>{" "}
                            <p>{Math.round(herb.value * 10) / 10}</p>
                          </li>
                        ))}
                    </ul>

                    <div className={styles.price_table}>
                      <table>
                        <thead>
                          <tr>
                            <th>Benämning</th>
                            <th>Summa</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr style={{ borderTop: "2px solid #adb5bd" }}>
                            <td>Startavgift</td>
                            <td>{prices.startFee} kr</td>
                          </tr>

                          {bottles &&
                            bottles.map(({ name, count, bottle }, index) => (
                              <tr key={index}>
                                <td>
                                  {count} st - {name} ({bottle.price} kr/flaska)
                                </td>
                                <td>{count * bottle.price} kr</td>
                              </tr>
                            ))}
                          {/* <tr>
                            <td>Frakt</td>
                            <td>0 kr</td>
                          </tr> */}
                          <tr>
                            <td>Summa</td>
                            <td>{sumToPay()} kr</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </details>
              </div>
            </div>
          </div>
        )}

        <div className={styles.smts}>
          <div className={styles.sammanstallning}>
            <h2>Sammanställning</h2>
            <li className={styles.total}>
              <p>Summa</p>
              <p className={styles.bold}>{sumToPay()} kr</p>
            </li>
            <div className={styles.separate} />
            <li className={styles.frakt}>
              <p>Frakt</p>
              <p className={styles.bold}>{handlePostFee()} kr</p>
            </li>
            <div className={styles.separate} />
            {discount.amount > 0 ? (
              <>
                <li className={styles.frakt}>
                  <img
                    src={trash}
                    alt={"remove"}
                    onClick={() => removeDiscount(discount.id)}
                  />
                  <p>Rabatt</p>
                  <p className={styles.bold} style={{ color: "red" }}>
                    {discount.amount}:-
                  </p>
                </li>
                <div className={styles.separate} />
              </>
            ) : (
              ""
            )}
            <li className={styles.sum}>
              <p className={styles.bold} style={{ fontSize: "18px" }}>
                Totalt
              </p>

              <p className={styles.bold} style={{ fontSize: "18px" }}>
                {totalToPay()} kr
              </p>
            </li>
            <li className={styles.moms} style={{ paddingBottom: "8px" }}>
              <p className={styles.small}>Varav moms</p>
              <p className={styles.small}>{calcMoms(sumToPay())} kr</p>
            </li>
            <div className={styles.separate} />
            {getSystemCompanyFee() !== 0 ? (
              <li className={styles.moms}>
                <p className={styles.small}>
                  Varav betalning till Systembolaget vid leverans
                </p>
                <p className={styles.small}>{getSystemCompanyFee()} kr</p>
              </li>
            ) : (
              <li className={styles.moms}>
                <p className={styles.small}>Varav alkoholskatt</p>
                <p className={styles.small}>{getAlcoTax()} kr</p>
              </li>
            )}
            <div className={styles.sammanbtn}>{handleKassaBtn()}</div>
          </div>
        </div>

        <form onSubmit={(e) => handleDiscount(e)}>
          <input
            type="text"
            value={discountCode}
            placeholder="Har du en rabattkod?"
            onChange={(e) => handleDiscountInput(e)}
          />
          <input type="submit" value="Lägg till" />
        </form>
      </div>

      <div>
        <Footer />
      </div>
    </>
  );
};

export default ShoppingCart;
