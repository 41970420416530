import React, { useState, useEffect } from "react";
import styles from "./HerbPagination.module.scss";
import arrowBlack from "../../assets/down_arrow_black.svg";
import useWindowDimensions from "../CustomHooks/useWindowDimensions";

const HerbPagination = ({ header, herbs, onAddHerb }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [herbsPerPage, setHerbsPerPage] = useState(4);

  const { width } = useWindowDimensions();

  const indexOfLastRecipe = currentPage * herbsPerPage;
  const indexOfFirstRecipe = indexOfLastRecipe - herbsPerPage;
  const currentItems = herbs.slice(indexOfFirstRecipe, indexOfLastRecipe);

  const maxPages = herbs.length / herbsPerPage;

  useEffect(() => {
    setCurrentPage(1);
    /* console.log("HERBS: ", herbs); */
  }, []);

  useEffect(() => {
    if (width <= 505) {
      setHerbsPerPage(1);
    } else if (width <= 1150) {
      setHerbsPerPage(2);
    } else if (width <= 1400) {
      setHerbsPerPage(3);
    } else {
      setHerbsPerPage(4);
    }
  }, [width]);

  const handlePrevbtn = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleNextbtn = () => {
    setCurrentPage(currentPage + 1);
  };

  return (
    <>
      <div className={styles.herblist}>
        <p className={styles.title}>{header}</p>
        <button
          className={styles.prv}
          style={{ opacity: currentPage === 1 ? 0.1 : 1 }}
          onClick={() => handlePrevbtn()}
          disabled={currentPage === 1 ? true : false}
        >
          <img
            src={arrowBlack}
            alt="arrow"
            style={{ transform: "rotate(90deg)" }}
          />
        </button>

        <ul>
          {currentItems.length === 0 ? (
            "Inga kryddor hittades..."
          ) : (
            <>
              {currentItems.map((herb, index) => (
                <li key={index} className={styles.herbList}>
                  <label>
                    <input
                      type="checkbox"
                      onChange={(e) => onAddHerb(e, herb)}
                      disabled={!herb.deletable}
                      checked={herb.selected}
                    />
                    <span />
                    <div className={styles.name}>{herb.display}</div>
                  </label>
                </li>
              ))}
            </>
          )}
        </ul>

        <button
          className={styles.nxt}
          onClick={() => handleNextbtn()}
          style={{ opacity: currentPage >= maxPages ? 0.1 : 1 }}
          disabled={currentPage >= maxPages ? true : false}
        >
          <img
            src={arrowBlack}
            alt="arrow"
            style={{ transform: "rotate(-90deg)" }}
          />
        </button>
      </div>
      <hr />
    </>
  );
};

export default HerbPagination;
