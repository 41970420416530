import React, { Component } from "react";
import styles from "./GinCooker.module.scss";
import globalStyles from "../global.module.scss";
import Navbar from "./Navbar/Navbar.jsx";
import RecipeBuilder from "./RecipeBuilder.js";
import Footer from "./Footer/Footer.jsx";
import Cookies from "universal-cookie";

import bannerBild from "../assets/images/06_ginsmalltopimg_1920x192.jpg";

const cookies = new Cookies();

export class GinCooker extends Component {
  static displayName = GinCooker.name;
  constructor() {
    super();
    this.onRecipeChange = this.onRecipeChange.bind(this);
    this.onBottleChange = this.onBottleChange.bind(this);
    this.onDateChange = this.onDateChange.bind(this);
    this.onLoggedIn = this.onLoggedIn.bind(this);
    this.onHerbChange = this.onHerbChange.bind(this);

    this.state = {
      state: {},
      authenticated: false,
      recipe: {},
      t1: {},
      t2: {},
      t3: {},
      tc: {},
      categories: [],
      bookingDate: new Date(),
      authInfo: {},
      herbs: [],
      bottleConfigurator: {
        bottleCount: 0,
        categories: [],
      },
      confirmationLoaded: false,
      intervalId: 0,
    };
  }

  componentDidMount() {
    let userData = sessionStorage.getItem("userData");
    if (userData != null) {
      fetch("login/validate?token=" + JSON.parse(userData).key)
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          this.setState({
            ...this.state,
            authInfo: data,
            authenticated: data.loggedIn,
          });
        })
        .catch((error) => {
          this.setState({
            ...this.state,
            authenticated: false,
          });
        });
    }

    const newInterval = setInterval(() => {
      this.checkSessionStatus();
    }, 120000);
    this.setState({ intervalId: newInterval });
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalId);
  }

  checkSessionStatus = () => {
    const route = "/publicapi/Recipes/sessionstatus";
    fetch(route, {
      method: "GET",
      headers: {
        "X-Session-Id": cookies.get("recipe-session-id"),
      },
    })
      .then((response) => {
        return response;
      })
      .then((data) => {
        if (data.status === 404) {
          sessionStorage.removeItem("gin-recipeid");
          sessionStorage.removeItem("userData");
          clearInterval(this.state.intervalId);
          this.props.history.push("/");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  onRecipeChange = (recipe, t1 = 0, t2 = 0, t3 = 0, tc = 0) => {
    this.setState({
      ...this.state,
      recipe: recipe,
      t1: t1,
      t2: t2,
      t3: t3,
      tc: tc,
    });

    let change = {
      temperature1: t1,
      temperature2: t2,
      temperature3: t3,
      temperatureCount: parseInt(tc),
    };

    if (cookies.get("recipe-session-id")) {
      cookies.set("recipe-session-id", cookies.get("recipe-session-id"), {
        path: "/",
        maxAge: 1800,
        sameSite: "none",
        secure: true,
      });
    }

    const putMethod = {
      method: "PUT",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "X-Session-Id": cookies.get("recipe-session-id"),
      },
      body: JSON.stringify(change),
    };

    const route = "/publicapi/Recipes/settings";
    fetch(route, putMethod)
      .then((response) => {
        if (response.status === 401) {
          window.location.href = "/ginshoppen";
          return;
        }
        return response.json();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  onBottleChange = (categories) => {
    this.setState({
      ...this.state,
      bottleConfigurator: categories,
    });

    const putMethod = {
      method: "PUT",
      headers: {
        "Content-type": "application/json",
        "X-Session-Id": cookies.get("recipe-session-id"),
      },
      body: JSON.stringify(categories),
    };

    const route = "publicapi/categories";
    fetch(route, putMethod)
      .then((response) => {
        if (response.status === 401) {
          window.location.href = "/ginshoppen";
          return;
        }
        return response.json();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  onDateChange = (date) => {
    this.setState({
      ...this.state,
      bookingDate: date.date,
    });
  };

  onHerbChange = (selectedHerbs) => {
    this.setState({
      ...this.state,
      herbs: selectedHerbs,
    });
  };

  onLoggedIn = (data) => {
    this.setState(
      {
        ...this.state,
        authInfo: data,
        authenticated: true,
      },
      () => {
        sessionStorage.setItem("userData", JSON.stringify(data));
      }
    );
  };

  onConfirmationLoaded = (value) => {
    this.setState({ confirmationLoaded: value });
  };

  checkRoute = (route) => {
    if (
      route === "/ginshoppen/wizard" ||
      route === "/ginshoppen/wizard_mangd" ||
      route === "/ginshoppen/wizard_typ"
    )
      return "none";
  };

  static renderCooker(
    props,
    state,
    onRecipeChangeCallback,
    onBottleChange,
    onConfirmationLoaded,
    updateSessionId,
  ) {
    return (
      <div>
        <RecipeBuilder
          data={state}
          onKalle={onRecipeChangeCallback}
          onChangeBottleConfig={onBottleChange}
          onConfirmationLoaded={onConfirmationLoaded}
          updateSessionId={() => updateSessionId()}
          history={props.history}
          match={props.match}
        />
      </div>
    );
  }

  render() {
    let contents =
      /* !this.state.authenticated ? (
      <Login onKalle={this.onLoggedIn} />
    ) : ( */
      GinCooker.renderCooker(
        this.props,
        this.state,
        this.onRecipeChange,
        this.onBottleChange,
        this.onConfirmationLoaded,
        this.updateSessionId
      );
    /* ); */

    return (
      <>
        <Navbar authenticated={!this.state.authenticated} />
        <div
          id="tabelLabel"
          className={styles.title}
          style={{
            backgroundImage: `URL(${bannerBild})`,
            backgroundSize: "cover",
            backgroundPosition: "50% 50%",
            textAlign: "left",
            display: this.checkRoute(window.location.pathname),
          }}
        >
          <h1
            style={{
              maxWidth: "1440px",
              margin: "0 auto",
              fontWeight: "bold",
              fontSize: "52px",
            }}
          >
            Gin shoppen
          </h1>
        </div>
        <div
          className={`${globalStyles.max_width} ${styles.contents}`}
          style={{ marginBottom: "320px" }}
        >
          {contents}
        </div>
        <div
          style={{
            display:
              window.location.pathname === "/ginshoppen/wizard"
                ? "none"
                : "" || window.location.pathname === "/ginshoppen/wizard_mangd"
                ? "none"
                : "",
          }}
        >
          <Footer />
        </div>
      </>
    );
  }
}
