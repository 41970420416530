import React from "react";
import styles from "./HomeCard.module.scss";
import { Link } from "react-router-dom";
import orange_arrow from "../../../assets/icons/orange_arrow_right.png";

const HomeCard = ({ ordning, title, image, link, linkText }) => {
  return (
    <Link
      to={link}
      style={{ gridArea: `${ordning}`, textDecoration: "none" }}
      className={styles.card}
    >
      <div className={styles.content}>
        <div
          className={styles.image}
          style={{
            backgroundImage: image && `URL(${image})`,
          }}
        />
        <div className={styles.overlay} />
        <div className={styles.text}>
          <h2>{title}</h2>
          <p>
            {linkText} <img src={orange_arrow} alt="" />
          </p>
        </div>
      </div>
    </Link>
  );
};

export default HomeCard;
