import React, { useRef, useState } from "react";
import styles from "./Navbar.module.scss";
import globalStyles from "../../global.module.scss";
import { NavLink as Link } from "react-router-dom";
import MenuItems from "./MenuItems.js";
import logo from "../../assets/qr-code-logo.png";
import { useHistory } from "react-router-dom";

const Navbar = ({ authenticated }) => {
  const [auth] = useState(authenticated);
  const mobileMenuRef = useRef();
  const toggleMobileMenu = () =>
    mobileMenuRef.current?.classList.toggle(styles.active);

  const history = useHistory();

  const handleLogout = () => {
    let userData = sessionStorage.getItem("userData");
    let key = JSON.parse(userData).key;

    const putMethod = {
      method: "PUT",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
      body: JSON.stringify({ key: key }),
    };

    const route = "login/logout";

    fetch(route, putMethod)
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const navigate = (url) => {
    history.push(url);
  };

  return (
    <nav className={globalStyles.navextra}>
      <div className={`${globalStyles.max_width} ${styles.container}`}>
        <Link to="/">
          <img
            alt="logo"
            src={logo}
            className={styles.logo}
            onClick={() => window.scrollTo(0, 0)}
          />
        </Link>

        <ul ref={mobileMenuRef}>
          {MenuItems.map((item, index) => (
            <Link
              exact={item.exact}
              to={item.url}
              activeClassName={styles.selected}
              key={index}
            >
              <li className={item.dropdown && styles.dropdown}>{item.title}</li>
            </Link>

            /* {item.dropdown && (
                <ul style={{display: "none"}} className={styles.dropdown_content}>
                  {item.navItems.map((item, index) => (
                    <li key={index} onClick={() => navigate(item.url)}>
                      {item.name}
                    </li>
                  ))}
                </ul>
              )} */
          ))}
        </ul>

        <div className={styles.mobileMenu} onClick={() => toggleMobileMenu()}>
          <div className={styles.bar}></div>
          <div className={styles.bar}></div>
          <div className={styles.bar}></div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
