import React, { useEffect, useState } from "react";
import styles from "./BottleConfigurator.module.scss";
import Cookies from "universal-cookie";
import Bottles from "./Bottles/Bottles";
const cookies = new Cookies();

const BottleConfigurator = ({
  data,
  onKalle,
  fetchWithSessionId,
  fetchCart,
  addBottlesToCart,
  cartInfo,
}) => {
  const [bottles, setBottles] = useState([]);

  useEffect(() => {
    fetchBottlesNew();
  }, []);

  const fetchBottlesNew = () => {
    fetchWithSessionId("publicapi/bottle")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setBottles(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <h1 className={styles.main_heading}>Antal, typ och styrka</h1>
      <div className={styles.bottleconfig_container}>
        {bottles && (
          <div className={styles.bottle_cont}>
            <div className={styles.bottle_head}>
              <ul>
                <li>Typ och Styrka</li>
                <li>Pris/flaska</li>
                <li>Antal flaskor</li>
              </ul>
            </div>
            <div className={styles.bottle_body}>
              {bottles.map(
                ({ bottleId, description, price, minimumOrder }, index) => (
                  <Bottles
                    key={index}
                    index={index}
                    description={description}
                    price={price}
                    bottleId={bottleId}
                    minimumOrder={minimumOrder}
                    cartInfo={cartInfo}
                    addBottlesToCart={addBottlesToCart}
                    fetchCart={fetchCart}
                  />
                )
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default BottleConfigurator;
