import React, { useState, useEffect } from "react";
import Navbar from "./Navbar/Navbar.jsx";
import styles from "./Kassa.module.scss";
import globalStyles from "../global.module.scss";
import { Form, Formik, useField } from "formik";
import * as Yup from "yup";
import Cookies from "universal-cookie";
import Login from "./Login/Login.jsx";

import bannerBild from "../assets/images/10_ginsmalltopimg_1920x192.jpg";
import Footer from "./Footer/Footer.jsx";

const CustomTextInput = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <div className={props.className}>
      <label htmlFor={props.id || props.name}>{label}</label>
      <br />
      <input
        className={styles.text_input}
        {...field}
        {...props}
        id={props.name}
        placeholder={props.placeholder}
        disabled={props.disabled}
        readOnly={props.readOnly}
      />
      {meta.touched && meta.error ? (
        <div className={styles.error}>{meta.error}</div>
      ) : null}
      <div style={{ marginBottom: "8px" }} />
    </div>
  );
};

const CustomMultiInput = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <div className={props.className}>
      <label htmlFor={props.id || props.name}>{label}</label>
      <br />
      <textarea
        className={styles.text_input}
        {...field}
        {...props}
        id={props.name}
        placeholder={props.placeholder}
        disabled={props.disabled}
        readOnly={props.readOnly}
        rows="2"
        cols="auto"
        style={{ maxWidth: "100%", minWidth: "100%", minHeight: "64px" }}
      />
      {meta.touched && meta.error ? (
        <div className={styles.error}>{meta.error}</div>
      ) : null}
      <div style={{ marginBottom: "8px" }} />
    </div>
  );
};

const CustomCheckbox = ({ children, ...props }) => {
  const [field, meta] = useField(props, "checkbox");
  return (
    <>
      <br />
      <label>
        <input type="checkbox" checked={field.value} {...field} {...props} />
        {children}
      </label>
      {meta.touched && meta.error ? (
        <div className={styles.error}>{meta.error}</div>
      ) : null}
    </>
  );
};

const cookies = new Cookies();

const Kassa = () => {
  const [authInfo, setAuthInfo] = useState();
  const [loadForm, setLoadForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [pNum, setPnum] = useState("");
  const [adress, setAdress] = useState("");
  const [city, setCity] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [epost, setEpost] = useState("");
  const [mobilnummer, setMobilnummer] = useState("");
  const [orderComment, setOrderComment] = useState("");
  const [consultationWanted, setConsultationWanted] = useState(false);

  const [discount, setDiscount] = useState(0);
  const [cartItems, setCartItems] = useState([]);
  const [recipeItem, setRecipeItem] = useState([]);
  const [bottles, setBottles] = useState([]);
  const [prices, setPrices] = useState([]);
  const [disabled, setDisabled] = useState(true);

  const onLoggedIn = () => {
    setAuthenticated(true);
  };

  useEffect(() => {
    if (authenticated) {
      let userData = sessionStorage.getItem("userData");
      setAuthInfo(userData);

      setFirstName(JSON.parse(userData).customer.firstName);
      setLastName(JSON.parse(userData).customer.lastName);
      setPnum(JSON.parse(userData).regNbr);
      setAdress(JSON.parse(userData).customer.address);
      setCity(JSON.parse(userData).customer.city);
      setPostalCode(JSON.parse(userData).customer.postalCode);
      setEpost(JSON.parse(userData).customer.email);
      setMobilnummer(JSON.parse(userData).customer.mobilePhoneNumber);
      if (JSON.parse(localStorage.getItem("consultation-wanted")) === null) {
        setConsultationWanted(false);
      } else {
        setConsultationWanted(
          JSON.parse(localStorage.getItem("consultation-wanted"))
        );
      }
      setLoadForm(true);
    }
  }, [authenticated]);

  useEffect(() => {
    let userData = sessionStorage.getItem("userData");
    if (userData) {
      setAuthenticated(JSON.parse(userData).loggedIn);
    }
  }, []);

  useEffect(() => {
    fetchPriceList();
    fetchCartItems();
  }, []);

  useEffect(() => {
    handleSubmitState();
  }, [cartItems, bottles, recipeItem]);

  const fetchCartItems = () => {
    const postMethod = {
      method: "GET",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "X-Session-Id": cookies.get("recipe-session-id"),
      },
    };
    fetch("publicapi/Cart", postMethod)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setCartItems(data.items);
        data.items.forEach((item) => {
          if (item.type === 0) {
            setRecipeItem(item);
          } else if (item.type === 2) {
            setDiscount(item);
          } else if (item.type === 3) {
            if (!bottles.includes(item)) {
              setBottles((bottles) => [...bottles, item]);
            }
          }
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchPriceList = () => {
    const postMethod = {
      method: "GET",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "X-Session-Id": cookies.get("recipe-session-id"),
      },
    };
    fetch("publicapi/settings/pricelist", postMethod)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setPrices(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handlePostFee = () => {
    let bottleAmnt = 0;
    let postFee = 0;

    bottles.forEach((bottle) => {
      bottleAmnt += bottle.count;
    });

    if (bottleAmnt >= 8) {
      postFee = prices.postFee4;
    } else if (bottleAmnt >= 6) {
      postFee = prices.postFee3;
    } else if (bottleAmnt >= 4) {
      postFee = prices.postFee2;
    } else {
      postFee = prices.postFee1;
    }

    return postFee;
  };

  const sumToPay = () => {
    let price = prices.startFee;

    cartItems.forEach((item) => {
      if (item.type === 3) {
        price += item.count * item.bottle.price;
      }
    });

    return price;
  };

  const totalToPay = () => {
    let total = sumToPay() + handlePostFee();

    if (discount.amount > 0) {
      return total - discount.amount;
    } else {
      return total;
    }
  };

  const calcMoms = (price) => {
    var exmoms = price / 1.25;
    var moms = price - exmoms;
    var momsRounded = moms.toFixed(2);

    return momsRounded;
  };

  const getSystemCompanyFee = () => {
    let totalSCfee = 0;
    bottles &&
      bottles.forEach((bottle) => {
        totalSCfee += bottle.count * bottle.bottle.systemCompanyPrice;
      });

    return Math.round(totalSCfee * 10) / 10;
  };

  const getAlcoTax = () => {
    let alcoTax = prices.alcoTax;
    let totalAlcoTax = 0;
    bottles &&
      bottles.forEach((bottle) => {
        totalAlcoTax +=
          bottle.count * 0.5 * Number(`0.${bottle.bottle.alcVol}`) * alcoTax;
      });

    return totalAlcoTax.toFixed(2);
  };

  const onSubmit = (data) => {
    setLoading(true);

    const postMethod = {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        authHeader: JSON.parse(authInfo).key,
        "X-Session-Id": cookies.get("recipe-session-id"),
      },
      body: JSON.stringify(data),
    };

    fetch("api/Order", postMethod)
      .then((response) => {
        if (response.status === 401) {
          window.location.href = "/ginshoppen";
          return;
        }
        return response.json();
      })
      .then((data) => {
        localStorage.setItem("orderRef", JSON.stringify(data.orderReferense));
        setTimeout(() => {
          window.location.href = "/ginshoppen/tack_for_din_bestallning";
          localStorage.removeItem("consultation-wanted");
          setLoading(false);
        }, 2000);

        /* fetch("api/Klarna", {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            authHeader: props.data.authInfo.key,
          },
          body: JSON.stringify(data),
        })
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            setKlarnaSnippet(data.html_snippet);
            setLoading(false);
            setOpen(true);
          })
          .catch((error) => {
            setLoading(false);
            console.log(error);
          }); */
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  const handleSubmitState = () => {
    if (cartItems.length === 0) {
      setDisabled(true);
    } else if (bottles.length === 0) {
      setDisabled(true);
    } else if (recipeItem.length === 0) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  };

  return (
    <div>
      <Navbar />
      <div
        id="tabelLabel"
        className={styles.title}
        style={{
          backgroundImage: `URL(${bannerBild})`,
          backgroundSize: "cover",
          backgroundPosition: "50% 50%",
          textAlign: "left",
        }}
      >
        <h1
          style={{
            maxWidth: "1440px",
            margin: "0 auto",
            fontWeight: "bold",
            fontSize: "52px",
            color: "var(--clr-gray-8)",
          }}
        >
          Lägg beställning
        </h1>
      </div>
      <div className={`${styles.kassa_container} ${globalStyles.max_width}`}>
        {authenticated ? "" : <Login onKalle={() => onLoggedIn()} />}
        {authenticated && (
          <>
            <div className={styles.order_info}>
              {loadForm ? (
                <Formik
                  initialValues={{
                    firstName: firstName,
                    lastName: lastName,
                    personnummer: pNum,
                    address: adress,
                    postalCode: postalCode,
                    city: city,
                    email: epost !== null ? epost : "",
                    mobilePhoneNumber: mobilnummer !== null ? mobilnummer : "",
                    orderComment: orderComment,
                    consultationWanted: consultationWanted,
                    acceptedTerms: false,
                  }}
                  validationSchema={Yup.object({
                    firstName: Yup.string()
                      .required("*Obligatoriskt fält")
                      .nullable(),
                    lastName: Yup.string()
                      .required("*Obligatoriskt fält")
                      .nullable(),
                    personnummer: Yup.string().required("*Obligatoriskt fält"),
                    address: Yup.string()
                      .required("*Obligatoriskt fält")
                      .nullable(),
                    postalCode: Yup.string()
                      .required("*Obligatoriskt fält")
                      .nullable(),
                    city: Yup.string()
                      .required("*Obligatoriskt fält")
                      .nullable(),
                    email: Yup.string()
                      .email("Ogiltig e-postadress")
                      .required("*Obligatoriskt fält")
                      .nullable(),
                    mobilePhoneNumber: Yup.string()
                      .required("*Obligatoriskt fält")
                      .nullable(),
                    orderComment: Yup.string().nullable(),
                    consultationWanted: Yup.string().nullable(),
                    acceptedTerms: Yup.boolean()
                      .required("*Obligatoriskt")
                      .oneOf(
                        [true],
                        "Du måste acceptera Ginkällans Köp- och Leveransvillkor"
                      ),
                  })}
                  onSubmit={(values, { setSubmitting }) => {
                    setTimeout(() => {
                      onSubmit(values);
                      setSubmitting(false);
                      /* setOpen(true); */
                    }, 500);
                  }}
                >
                  {(props) => (
                    <Form
                      onSubmit={props.handleSubmit}
                      id="formikForm"
                      className={styles.form_c}
                    >
                      <CustomTextInput
                        value={firstName}
                        label="Namn"
                        name="firstName"
                        type="text"
                        className={styles.fnamn}
                      />
                      <CustomTextInput
                        value={lastName}
                        label="Efternamn"
                        name="lastName"
                        type="text"
                        className={styles.lnamn}
                      />
                      <CustomTextInput
                        value={pNum}
                        label="Personnummer"
                        name="personnummer"
                        type="text"
                        className={styles.personnummer}
                        readOnly
                      />
                      <CustomTextInput
                        label="Adress"
                        name="address"
                        type="text"
                        className={styles.adress}
                      />
                      <CustomTextInput
                        label="Postort"
                        name="city"
                        type="text"
                        className={styles.postort}
                      />
                      <CustomTextInput
                        label="Postnummer"
                        name="postalCode"
                        type="text"
                        className={styles.postnummer}
                      />
                      <CustomTextInput
                        label="E-post"
                        name="email"
                        type="text"
                        className={styles.epost}
                      />
                      <CustomTextInput
                        label="Mobilnummer"
                        name="mobilePhoneNumber"
                        type="text"
                        className={styles.mobilnummer}
                      />
                      <CustomMultiInput
                        label="Kommentar"
                        name="orderComment"
                        type="text"
                        className={styles.ordercomment}
                      />
                      <div className={styles.consultation}>
                        <CustomCheckbox name="consultationWanted">
                          Kontakta mig för en personlig genomgång av mitt recept
                          innan min gin destilleras.
                        </CustomCheckbox>
                      </div>
                      <div className={styles.terms}>
                        <CustomCheckbox name="acceptedTerms">
                          Jag har läst och godkänt Ginkällans{" "}
                          <a
                            href="/kop-och-leveransvillkor"
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            Köp- och Leveransavtal
                          </a>
                        </CustomCheckbox>
                      </div>

                      <button
                        type="submit"
                        form="formikForm"
                        className={styles.buyBtn}
                        disabled={disabled || !(props.isValid && props.dirty)}
                      >
                        {loading ? "Laddar.." : "Lägg beställning"}
                      </button>
                    </Form>
                  )}
                </Formik>
              ) : (
                ""
              )}
            </div>

            <div className={styles.sammanstallning}>
              <h2>Sammanställning</h2>
              <li className={styles.total}>
                <p>Summa</p>
                <p className={styles.bold}>{sumToPay()} kr</p>
              </li>
              <div className={styles.separate} />
              <li className={styles.frakt}>
                <p>Frakt</p>
                <p className={styles.bold}>{handlePostFee()} kr</p>
              </li>
              <div className={styles.separate} />
              {discount.amount > 0 ? (
                <>
                  <li className={styles.frakt}>
                    <p>Rabatt</p>
                    <p className={styles.bold} style={{ color: "red" }}>
                      {discount.amount}:-
                    </p>
                  </li>
                  <div className={styles.separate} />
                </>
              ) : (
                ""
              )}
              <li className={styles.sum}>
                <p className={styles.bold} style={{ fontSize: "18px" }}>
                  Totalt
                </p>
                <p className={styles.bold} style={{ fontSize: "18px" }}>
                  {totalToPay()} kr
                </p>
              </li>
              <li className={styles.moms} style={{ paddingBottom: "8px" }}>
                <p className={styles.small}>Varav moms</p>
                <p className={styles.small}>{calcMoms(sumToPay())} kr</p>
              </li>
              <div className={styles.separate} />
              {getSystemCompanyFee() !== 0 ? (
                <li className={styles.moms} style={{ paddingBottom: "8px" }}>
                  <p className={styles.small}>
                    Varav betalning till Systembolaget vid leverans
                  </p>
                  <p className={styles.small}>{getSystemCompanyFee()} kr</p>
                </li>
              ) : (
                <li className={styles.moms} style={{ paddingBottom: "8px" }}>
                  <p className={styles.small}>Varav alkoholskatt</p>
                  <p className={styles.small}>{getAlcoTax()} kr</p>
                </li>
              )}
            </div>

            <div>
              <Footer />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Kassa;
