import React, { useEffect, useState } from "react";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import styles from "./Slider.module.scss";
import minus from "../assets/icons/minus.png";
import plus from "../assets/icons/plus.png";

const DEFAULT_CLASS_NAMES = {
  inputRange: "input-range",
  slider: "input-range__slider",
  labelContainer: "input-range__label-container",
  sliderContainer: "input-range__slider-container",
  minLabel: "input-range__label input-range__label--min",
  maxLabel: "input-range__label input-range__label--max",
  disabledInputRange: "input-range input-range--disabled",
  track: "input-range__track input-range__track--background",
  valueLabel: "input-range__label input-range__label--value",
  activeTrack: "input-range__track input-range__track--active",
};

const Slider = ({
  onChangeFunc,
  data,
  max,
  min,
  value,
  label,
  decDisabled,
  incDisabled,
}) => {
  const [percentValue, setPercentValue] = useState();
  const { step, name, disabled } = data;
  const safeMin = data.safeMin;
  const safeMax = data.safeMax;

  const getZePorcont = () => {
    var range = max - safeMax;
    var correctedStartValue = value - safeMax;
    var porcont = (correctedStartValue * 100) / range;

    if (porcont <= 0) {
      setPercentValue(0);
    } else if (porcont >= 100) {
      setPercentValue(100);
    } else {
      setPercentValue(porcont);
    }
  };

  useEffect(() => {
    getZePorcont();
    /* console.log("percentage: ", percentValue); */
  }, [value]);

  const onChange = (range) => {
    onChangeFunc({
      type: data.name,
      value: Math.round(range * 10) / 10,
    });
  };

  const onDecrement = (value) => {
    if (value - step < min) {
      return;
    } else {
      onChange(value - step);
    }
  };

  const onIncrement = (value) => {
    if (value + step > max) {
      return;
    } else {
      onChange(value + step);
    }
  };

  return (
    <div className={styles.mainsl_container}>
      <label>{name}</label>
      <div className={styles.slider_container}>
        <InputRange
          classNames={{
            ...DEFAULT_CLASS_NAMES,
            valueLabel: `${styles.slider_valueLabel}`,
            track: `${DEFAULT_CLASS_NAMES.track} ${styles.slider_track}`,
            activeTrack: `${DEFAULT_CLASS_NAMES.activeTrack} ${styles.slider_track}`,
            slider: `${DEFAULT_CLASS_NAMES.slider} ${styles.slider_slider}`,
            inputRange: `${DEFAULT_CLASS_NAMES.inputRange} ${styles.slider_inputRange}`,
            sliderContainer: `${DEFAULT_CLASS_NAMES.sliderContainer} ${styles.slider_sliderContainer}`,
            labelContainer: `${DEFAULT_CLASS_NAMES.labelContainer} ${styles.slider_labelContainer}`,
            minLabel: `${DEFAULT_CLASS_NAMES.minLabel} ${styles.slider_minLabel}`,
            maxLabel: `${DEFAULT_CLASS_NAMES.maxLabel} ${styles.slider_maxLabel}`,
          }}
          formatLabel={label}
          disabled={disabled}
          minValue={min}
          maxValue={max}
          step={step}
          value={value}
          onChange={(e) => onChange(e)}
        />
      </div>

      <div
        className={styles.btn_group}
        style={{
          backgroundPosition: `0% ${percentValue}%`,
        }}
      >
        <button
          className={styles.decrement}
          onClick={() => onDecrement(value)}
          disabled={decDisabled}
        >
          <img src={minus} alt="decrement" />
        </button>
        <span className={`${styles.line} ${styles.line_one}`} />
        <p>{value}</p>
        <span className={`${styles.line} ${styles.line_two}`} />
        <button
          className={styles.increment}
          onClick={() => onIncrement(value)}
          disabled={incDisabled}
        >
          <img src={plus} alt="increment" />
        </button>
      </div>
    </div>
  );
};

export default Slider;
