import React, { useState, useEffect, useRef } from "react";
import { ScrollMenu } from "react-horizontal-scrolling-menu";
import { LeftArrow, RightArrow } from "./Arrows.tsx";
import styles from "./WizardConf.module.scss";
import VideoPlay from "./VideoPlay";
import Cookies from "universal-cookie";
import playbtn from "../../assets/icons/playIconDark.svg";
import "./HideScrollBar.css";

const cookies = new Cookies();

const WizardConf = ({ allHerbs, herbs, groups, id, onAddHerb }) => {
  const [wizardHeading, setWizardHeading] = useState("...");
  const [categoryVideo, setCategoryVideo] = useState("");
  const [herbArray, setHerbArray] = useState([]);
  const [maing] = useState([]);
  const [prevg] = useState([]);
  const [nextg] = useState([]);

  const ref = useRef(null);

  useEffect(() => {
    setWizardHeading(herbs[0].group.wizardHeading);
    setCategoryVideo(herbs[0].group.videoUrl);

    getGroup();
  }, [herbs]);

  useEffect(() => {
    let array = Object.values(Object.values(allHerbs));
    setHerbArray(array);
  }, [allHerbs]);

  const getGroup = () => {
    groups.forEach((group) => {
      if (!group.wizardSortingOrder) return;
      if (herbs[0].wizardgroupid === group.wizardSortingOrder) {
        maing.push(group);
      } else if (herbs[0].wizardgroupid - 1 === group.wizardSortingOrder) {
        prevg.push(group);
      } else if (herbs[0].wizardgroupid + 1 === group.wizardSortingOrder) {
        nextg.push(group);
      }
    });

    console.log(maing);
  };

  const scroll = (action) => {
    const up = document.getElementById(id - 1);
    const down = document.getElementById(id + 1);

    if (up && action === "up") {
      up.scrollIntoView({ behavior: "smooth" });
    } else if (down && action === "down") {
      down.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div key={id} id={id} className={styles.wizardconf}>
      <div
        className={styles.prevbtn}
        style={{
          visibility: id === 1 ? "hidden" : "",
          textAlign: "center",
        }}
      >
        <button onClick={() => scroll("up")}>
          <img alt="next_item" />
        </button>
        <h4 style={{ marginTop: "8px" }}>{prevg && prevg[0]?.name}</h4>
      </div>

      <div
        ref={ref}
        className={styles.cat_title}
        style={{
          marginTop: "48px",
        }}
      >
        <VideoPlay videoUrl={categoryVideo}>
          <h4>{maing && maing[0]?.wizardHeading}</h4>
        </VideoPlay>
      </div>

      <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow}>
        {herbs &&
          herbs.map((herb, index) => (
            <div key={index} className={styles.wizardherb}>
              <label className={styles.switch}>
                <input
                  type="checkbox"
                  onChange={(e) => onAddHerb(e, herb)}
                  disabled={!herb.deletable}
                  checked={herb.selected}
                />
                <span />
                <h4 className={styles.display_title}>{herb.display}</h4>
              </label>

              <div className={styles.imgBackground}>
                <VideoPlay videoUrl={herb.videoUrl}>
                  <img
                    alt=""
                    src={
                      herb.id
                        ? `https://www.ginkallan.se/static/media/img/herbs/${herb.id}.jpg`
                        : ""
                    }
                  />
                  <img
                    style={{ display: herb.videoUrl ? "" : "none" }}
                    className={styles.play_btn}
                    src={playbtn}
                  />
                </VideoPlay>
              </div>
            </div>
          ))}
      </ScrollMenu>

      {herbArray.length > id ? (
        <div className={styles.nextbtn}>
          <h4 style={{ textAlign: "center" }}>{nextg && nextg[0]?.name}</h4>
          <button onClick={() => scroll("down")}>
            <img alt="next_item" />
          </button>
        </div>
      ) : (
        ""
      )}

      {herbArray.length === id ? (
        <div className={styles.lastbtn}>
          <a href={"/ginshoppen/wizard_mangd"} className={styles.steptwo}>
            <h4>Mängd</h4>
            <img alt="next_item" />
          </a>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default WizardConf;
