import React, { useState, useEffect } from "react";
import styles from "./RecipePagination.module.scss";
import useWindowDimensions from "../CustomHooks/useWindowDimensions";
import arrowBlack from "../../assets/down_arrow_black.svg";
import VideoModal from "../Modals/VideoModal/VideoModal";
import playIcon from "../../assets/icons/playIcon.svg";

const RecipePagination = ({
  recipes,
  handleSelectRecipe,
  selectRecipe,
  handleSearch,
  disabledSearch,
  fetchWithAuthHeader,
  title,
  main,
  data,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [recipesPerPage, setRecipesPerPage] = useState(4);
  const [recipeInfo, setRecipeInfo] = useState([]);
  const [recipeName, setRecipeName] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [videoId, setVideoId] = useState();
  const { width } = useWindowDimensions();

  const indexOfLastRecipe = currentPage * recipesPerPage;
  const indexOfFirstRecipe = indexOfLastRecipe - recipesPerPage;
  const currentItems = recipes.slice(indexOfFirstRecipe, indexOfLastRecipe);

  const maxPages = recipes.length / recipesPerPage;

  useEffect(() => {
    setCurrentPage(1);
    recipes.reverse();
  }, [recipes]);

  //Change these with the corresponding media query width
  useEffect(() => {
    if (width <= 680) {
      setRecipesPerPage(1);
    } else if (width <= 825) {
      setRecipesPerPage(2);
    } else if (width <= 1500) {
      setRecipesPerPage(3);
    } else {
      setRecipesPerPage(4);
    }
  }, [width]);

  const handlePrevbtn = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleNextbtn = () => {
    setCurrentPage(currentPage + 1);
  };

  const toggleInfo = (recipe) => {
    fetchWithAuthHeader(`publicapi/recipes/${recipe.value}`)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setRecipeInfo(data.herbs);
        setRecipeName(data.name);
        if (data.url) {
          var parts = data.url.split("=");
          var answer = parts[parts.length - 1];
          setVideoId(answer);
        } else {
          setVideoId("");
        }
        return data;
      })
      .then((data) => {
        setIsOpen(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className={styles.recipe_container}>
      <div className={styles.title}>
        {title}
        {disabledSearch ? (
          <></>
        ) : (
          <input
            className={styles.search_input}
            type="text"
            onChange={(e) => handleSearch(e)}
            placeholder="Sök Recept"
          />
        )}
      </div>

      <button
        className={`${styles.prvBtn} ${styles.navBtn}`}
        style={{ opacity: currentPage === 1 ? 0.1 : 1 }}
        onClick={handlePrevbtn}
        disabled={currentPage === 1 ? true : false}
      >
        <img
          src={arrowBlack}
          alt="arrow"
          style={{ transform: "rotate(90deg)" }}
        />
      </button>
      <ul className={styles.recipe_ul}>
        {currentItems.length === 0 ? (
          "Inga recept hittades..."
        ) : (
          <>
            {currentItems.map((recipe, index) => (
              <li
                key={index}
                style={{
                  backgroundColor:
                    selectRecipe === recipe.display ? "var(--clr-cyan-4)" : "",
                  color:
                    selectRecipe === recipe.display
                      ? "#fff"
                      : "var(--clr-gray-7)",
                }}
              >
                <label>
                  <input
                    type="radio"
                    onChange={() => handleSelectRecipe(recipe)}
                    checked={selectRecipe === recipe.display ? "checked" : ""}
                  />
                  <span />
                </label>
                <div className={styles.recipe_info}>
                  {recipe.display}

                  {recipe.url && (
                    <img alt="play_video" onClick={() => toggleInfo(recipe)} />
                  )}
                </div>
              </li>
            ))}
          </>
        )}
      </ul>
      {videoId !== undefined ? (
        <VideoModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          videoId={videoId}
          recipeInfo={recipeInfo}
          data={data}
          recipeName={recipeName}
        />
      ) : (
        ""
      )}

      <button
        className={`${styles.nxtBtn} ${styles.navBtn}`}
        onClick={handleNextbtn}
        style={{ opacity: currentPage >= maxPages ? 0.1 : 1 }}
        disabled={currentPage >= maxPages ? true : false}
      >
        <img
          src={arrowBlack}
          alt="arrow"
          style={{ transform: "rotate(-90deg)" }}
        />
      </button>
    </div>
  );
};

export default RecipePagination;

{
  /* <ModalVideo
          channel="youtube"
          autoplay
          isOpen={openVideo}
          videoId={videoId}
          onClose={() => setOpenVideo(false)}
        /> */
}
