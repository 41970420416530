import React, { useState, useEffect } from "react";
import styles from "./Home.module.scss";
import client from "../services/contentful.js";
import HomeCard from "./Cards/HomeCard/HomeCard";

const Home = () => {
  const [qrCodes, setQrCodes] = useState([]);
  const [qrCode, setQrCode] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [homeBottle, setHomeBottle] = useState("");
  const [homeText, setHomeText] = useState("");
  const [homeCards, setHomeCards] = useState([]);
  const [homeInfo, setHomeInfo] = useState("");
  const [nyhet, setNyhet] = useState(false);

  const queryString = require("query-string");

  useEffect(() => {
      fetch(`publicapi/QrCodes`, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setQrCodes(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    getQr();
  }, [qrCodes]);

  useEffect(() => {
    for (var i = 0; i < qrCodes.length; i++) {
      if (qrCodes[i].id == qrCode.id) {
        redirectQr();
        break;
      }
    }
  }, [qrCode]);

  useEffect(() => {
    const getHomeContent = () => {
      client.getEntry("50d9g43bHK0evjlFOPfzNn").then((entry) => {
        setHomeInfo(entry.fields);
        setNyhet(entry.fields.nyhet);
        setHomeBottle(entry.fields.flaska.fields.file.url);
        setHomeText(entry.fields.makeyourgincometrue.fields.file.url);
      });

      client.getEntries({ content_type: "homeCard" }).then((entries) => {
        entries.items.forEach((card, index) => {
          setHomeCards((oldArray) => [...oldArray, card.fields]);
        });
      });

      setIsLoading(false);
    };

    getHomeContent();
  }, []);

  const getQr = () => {
    const qr = queryString.parse(window.location.search);
    const id = qr.id;
    if (id !== undefined) {
        fetch(`publicapi/QrCodes/${id}`, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
        },
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          setQrCode(data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const redirectQr = () => {
    if (qrCode !== undefined) {
      window.location.href = qrCode.url;
    }
  };

  return isLoading ? (
    <></>
  ) : (
    <div className={styles.home_container}>
      {nyhet && (
        <div className={styles.special}>
          <a href={homeInfo.nyhetLink} className={styles.offer}>
            <h2>{homeInfo.nyhetTitel}</h2>
            <p>{homeInfo.nyhetText}</p>
          </a>
        </div>
      )}

      <img className={styles.bottle} src={homeBottle} alt="gin flaska" />
      <img className={styles.text} src={homeText} alt="gör din gin" />
      <div className={styles.card_container}>
        {homeCards.map((card, index) => (
          <HomeCard
            key={index}
            ordning={card.ordning}
            title={card.title}
            image={card.image.fields.file.url}
            link={card.link}
            linkText={card.linkText}
          />
        ))}
      </div>
    </div>
  );
};

export default Home;
