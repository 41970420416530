import React from "react";
import styles from "./VideoModal.module.scss";
import Overview from "../../Overview/Overview.jsx";

const VideoModal = ({
  isOpen,
  setIsOpen,
  videoId,
  recipeInfo,
  data,
  recipeName,
}) => {
  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  return isOpen ? (
    <div className={styles.modal}>
      <div className={styles.content}>
        <span onClick={() => toggleModal()}>X</span>
        <div className={styles.video}>
          <iframe
            src={`https://www.youtube.com/embed/${videoId}`}
            frameBorder="0"
            allowFullScreen
          ></iframe>
        </div>
        {recipeInfo && (
          <div className={styles.ov}>
            <Overview
              name={`Recept - ${recipeName}`}
              recipe={recipeInfo}
              data={data}
            />
          </div>
        )}
      </div>
    </div>
  ) : null;
};

export default VideoModal;
