import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar/Navbar";
import styles from "./two_column_page.module.scss";
import globalStyles from "../global.module.scss";

import { marked } from "marked";
import client from "../services/contentful.js";
import Footer from "../components/Footer/Footer";

const Guide2 = () => {
  const [isLoading, setisLoading] = useState(true);
  const [banner, setBanner] = useState("");
  const [content, setContent] = useState([]);

  useEffect(() => {
    const getPageContent = () => {
      client.getEntry("3qpQPJHj5YIoTFJZD6DPHo").then((entry) => {
        setContent(entry.fields);
        setBanner(entry.fields.bannerBild.fields.file.url);
      });

      setisLoading(false);
    };

    getPageContent();
  }, []);

  return (
    <>
      {isLoading ? (
        <></>
      ) : (
        <div className={styles.two_column}>
          <Navbar />
          <div
            className={styles.banner}
            style={{ backgroundImage: `URL(https:${banner})` }}
          >
            <h1 className={globalStyles.max_width}>{content.pageTitle}</h1>
          </div>
          <div
            className={`${styles.content} ${globalStyles.max_width}`}
            dangerouslySetInnerHTML={{
              __html: marked(
                content.pageBeskrivning !== undefined || null
                  ? content.pageBeskrivning
                  : ""
              ),
            }}
          />
          <div>
            <Footer />
          </div>
        </div>
      )}
    </>
  );
};

export default Guide2;
