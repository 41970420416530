import React, { useState, useEffect } from "react";
import styles from "./Overview.module.scss";
import { Link } from "react-router-dom";
import Cookies from "universal-cookie";
import cartImg from "../../assets/icons/cartIcon.png";

const Overview = ({
  recipe,
  orgRecipe = [],
  changeTab,
  tabNr,
  name,
  reset,
  summary,
  data,
  addtocart = false,
  changeName = "Fortsätt",
  addtocartfunc,
  resetRecipeOr,
  total,
  restartbtn,
  resetSelectedbtn,
  resetbtn,
  updateRecipeBtn,
  cartamount,
  tabchange,
  cartInfo,
  consultation,
  consultationState,
}) => {
  const [diff, setDiff] = useState([]);
  const [startFee, setStartFee] = useState();
  const [bottlePrice, setBottlePrice] = useState(0);
  const [disabled, setDisabled] = useState(true);

  const cookies = new Cookies();

  useEffect(() => {
    if (orgRecipe.length !== 0) {
      const difference = recipe.filter(
        (herb1) => !orgRecipe.some((herb2) => herb1.name === herb2.name)
      );
      setDiff(difference);
    }

    /* recipe.sort(
      (a, b) => (a.sortOrder ?? Infinity) - (b.sortOrder ?? Infinity)
    ); */

    /* var test = []; */
  }, [recipe]);

  useEffect(() => {
    getPriceList();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [tabchange]);

  useEffect(() => {
    if (!summary) return;
    handleButtonState();
    if (!cartInfo.items) return;
    setBottlePrice(getBottlePrice());
    cartInfo.items.sort((a, b) => a.bottle?.bottleId - b.bottle?.bottleId);
  }, [cartInfo]);

  const handleButtonState = () => {
    setDisabled(true);
    cartInfo.items &&
      cartInfo.items.forEach((i) => {
        if (i.type === 3) setDisabled(false);
      });
  };

  const fetchWithAuthHeader = (path) => {
    return fetch(path, {
      method: "GET",
      headers: { "X-Session-Id": cookies.get("recipe-session-id") },
    });
  };

  const getPriceList = () => {
    fetchWithAuthHeader("publicapi/settings/pricelist")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setStartFee(data.startFee);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const setHighlight = (herb) => {
    if (orgRecipe.length !== 0) {
      if (diff.includes(herb)) {
        return "var(--clr-cyan-1)";
      } else if (Math.round(herb.value * 10) / 10 !== herb.amount) {
        return "var(--clr-cyan-1)";
      } else {
        return "#fff";
      }
    } else {
      return "#fff";
    }
  };

  const setFontWeight = (herb) => {
    if (orgRecipe.length !== 0) {
      if (Math.round(herb.value * 10) / 10 !== herb.amount) {
        return "bold";
      }
    }
  };

  const getTotalHerbs = () => {
    var total = 0;

    recipe.forEach((herb) => {
      total += herb.value;
    });

    return total;
  };

  const getBottlePrice = () => {
    if (!cartInfo.items) return;

    let price = 0;

    cartInfo.items.forEach((element) => {
      if (element.type === 3) {
        price += element.count * element.bottle.price;
      }
    });

    return price;
  };

  const handleBtnState = () => {
    if (updateRecipeBtn) {
      return "Uppdatera varukorgen";
    } else {
      return "Lägg till i varukorg";
    }
  };

  return (
    <div className={styles.overview_container}>
      <div className={styles.oheader}>
        <h4>{name}</h4>
      </div>

      {summary && (
        <>
          <ul className={styles.summaryList}>
            <li className={styles.topo}>
              <p>Benämning</p> <p>summa</p>
            </li>
            <li>
              <p>1 st - Gin recept</p> <p>{startFee} kr</p>
            </li>
            <div className={styles.separator} />
            {cartInfo.items &&
              cartInfo.items.map((bottle, index) =>
                bottle.type !== 3 ? (
                  <></>
                ) : (
                  <li className={styles.bottls} key={index}>
                    {bottle.count} st - 500 ml {bottle.name}
                    <p>{bottle.count * bottle.bottle.price} kr</p>
                  </li>
                )
              )}
            <div className={styles.separator} />
            <li style={{ fontWeight: "bold" }}>
              <p>Summa</p>
              <p>{startFee + bottlePrice} kr</p>
            </li>
            <div className={styles.separator} />
          </ul>
        </>
      )}

      {summary && <p className={styles.herb_heading}>Gin recept</p>}

      <ul style={{ paddingTop: summary && "0" }} className={styles.list}>
        {recipe &&
          recipe.map((herb, index) => (
            <React.Fragment key={index}>
              <li
                key={index}
                style={{
                  backgroundColor: setHighlight(herb),
                  fontWeight: diff.includes(herb) ? "bold" : "",
                }}
              >
                <p>
                  {herb.name} (
                  {herb.amount > 0
                    ? Math.round(herb.amount * 10) / 10
                    : Math.round(herb.value * 10) / 10}
                  g)
                </p>
                <p
                  style={{
                    fontWeight: setFontWeight(herb),
                  }}
                >
                  {Math.round(herb.value * 10) / 10}g
                </p>
              </li>

              <div className={styles.separator} />
            </React.Fragment>
          ))}

        {total && (
          <>
            <li className={styles.graybg} style={{ marginTop: "32px" }}>
              <p>Totalt mängd</p>
              <p>{Math.round(getTotalHerbs() * 10) / 10}g</p>
            </li>
            <div className={styles.separator} />
            <li className={styles.graybg}>
              <p>Totalt mängd per liter</p>
              <p>{Math.round((getTotalHerbs() / 6) * 10) / 10}g</p>
            </li>
            <div className={styles.separator} />
          </>
        )}
      </ul>

      <label
        className={styles.contactbtn}
        style={{
          backgroundColor:
            consultationState === "true" ? "var(--clr-cyan-4)" : "",
        }}
      >
        <input
          type="radio"
          onChange={() => consultation()}
          checked={consultationState === "true" ? "checked" : ""}
        />
        <span />
        <p>
          Kontakta mig för en personlig genomgång av mitt recept innan min gin
          destilleras.
        </p>
      </label>

      {changeTab && (
        <div
          className={styles.btngroup}
          style={{ gridTemplateColumns: "100%" }}
        >
          <button className={styles.cont} onClick={(e) => changeTab(e, tabNr)}>
            {changeName}
          </button>
        </div>
      )}

      {restartbtn && (
        <div className={styles.xtrabtn}>
          <button onClick={() => restartbtn()}>Nollställ recept</button>
        </div>
      )}

      {resetSelectedbtn && (
        <div className={styles.xtrabtn}>
          {sessionStorage.getItem("gin-recipeid") ? (
            <button onClick={() => resetSelectedbtn()}>Återställ</button>
          ) : (
            <button onClick={() => resetbtn()}>Återställ</button>
          )}
        </div>
      )}

      {addtocart && (
        <div className={styles.btngroup}>
          <button
            className={styles.cont}
            disabled={disabled}
            onClick={() => addtocartfunc()}
          >
            {handleBtnState()}
          </button>
          <Link
            className={styles.cart_icon}
            to={cartamount > 0 ? "/varukorg" : "/ginshoppen/typ_och_styrka"}
          >
            <span>
              <p>{cartamount > 0 ? cartamount : ""}</p>
            </span>
            <img src={cartImg} alt={"cart"} />
          </Link>
        </div>
      )}
    </div>
  );
};

export default Overview;
