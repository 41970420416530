import React, { useState, useRef } from "react";
import { HashLink as Link } from "react-router-hash-link";
import styles from "./Login.module.scss";
import { QRCodeSVG } from "qrcode.react";

import bankid_img from "../../assets/BankID_logo.svg";
import bankid_img_white from "../../assets/BankID_logo_white.svg";

const Login = ({ onKalle }) => {
  const [login, setLogin] = useState(false);
  const [qrCode, setQrCode] = useState("");
  const [loginTimeout, setLoginTimeout] = useState(false);
  const timerRef = useRef(null);

  function checkStatus(data) {
    let loginBody = {
      regNbr: data.regNbr,
      key: data.key,
    };

    const putMethod = {
      method: "PUT",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
      body: JSON.stringify(loginBody),
    };

    const clearTimer = () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
        timerRef.current = null;
        setLogin(false);
      }
    };

    timerRef.current = setInterval(() => {
      fetch("login/status", putMethod)
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          if (data.qrCode) {
            setQrCode(data.qrCode);
          }
          if (data.loggedIn) {
            clearTimer();
            sessionStorage.setItem("userData", JSON.stringify(data));
            onKalle();
            setLogin(false);
          }
          if (data.failedLogin) {
            clearTimer();
            setLogin(false);
          }
        })
        .catch((error) => {
          clearTimer();
          setLogin(false);
          console.error(error);
        });
    }, 2000);

    setTimeout(() => {
      clearTimer();
      setLoginTimeout(true);
    }, 26000);
  }

  const handleLogin = (num) => {
    let loginBody = {
      regNbr: num,
    };

    const putMethod = {
      method: "PUT",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
      body: JSON.stringify(loginBody),
    };

    fetch("login", putMethod)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setQrCode(data.qrCode);
        checkStatus(data);
      })
      .catch((error) => {
        console.error(error);
        setLogin(false);
      });
  };

  const onLogin = () => {
    setLogin(true);
    handleLogin();
    setLoginTimeout(false);
  };

  const handleCancel = () => {
    clearInterval(timerRef.current);
    setLogin(false);
    setLoginTimeout(false);
  };

  return (
    <div className={styles.login}>
      <div id="myModal" className={styles.modal}>
        <div className={styles.modalContent}>
          <Link
            to="/varukorg"
            onClick={() => handleCancel()}
            className={styles.close}
          >
            &times;
          </Link>

          <div className={styles.form_section}>
            {!login ? (
              loginTimeout ? (
                <div>
                  <h1>BankID login misslyckades</h1>
                  <button
                    onClick={() => handleCancel()}
                    style={{
                      float: "right",
                      border: "1px solid black",
                      width: "100%",
                      padding: "12px",
                      marginTop: "24px",
                    }}
                  >
                    Försök igen
                  </button>
                </div>
              ) : (
                <div>
                  <img alt="bankId" src={bankid_img} />
                  <h1>Logga in</h1>
                  <span>Åldersgräns: 20 år</span>
                  <button onClick={() => onLogin()} className={styles.loginBtn}>
                    <img
                      alt="bankId_login"
                      src={bankid_img_white}
                      style={{ height: "36px", width: "36px" }}
                    />
                    Logga in med BankID
                  </button>
                </div>
              )
            ) : (
              <div>
                <h1>Legitimera med BankID</h1>
                <div
                  style={{
                    display: "grid",
                    width: "max-content",
                    margin: "32px auto",
                  }}
                >
                  {qrCode && <QRCodeSVG value={qrCode} size={192} />}
                </div>
                <button
                  onClick={() => handleCancel()}
                  style={{
                    float: "right",
                    border: "1px solid black",
                    width: "100%",
                    padding: "12px",
                    marginTop: "24px",
                  }}
                >
                  Avbryt inlogging
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
