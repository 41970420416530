const MenuItems = [
  {
    title: "Gin Shoppen",
    url: "/ginshoppen",
    dropdown: true,
    navItems: [
      { name: "Easy", url: "/ginshoppen/wizard" },
      { name: "Advanced", url: "/ginshoppen/recepten" },
    ],
  },
  {
    title: "Guiden - Hur det går till",
    url: "/guiden-hur-det-gar-till",
    exact: true,
  },
  { title: "Ginskolan", url: "/ginskolan", exact: true },
  { title: "Frågor & Svar", url: "/faq", exact: true },
  { title: "Media", url: "/media", exact: true },
];

export default MenuItems;
