import React from "react";
import { Route, Redirect, Switch } from "react-router";
import Layout from "./components/Layout/Layout";
import Home from "./components/Home";
import { GinCooker } from "./components/GinCooker";
import KopOchLeveransvillkor from "./pages/KopOchLeveransvillkor";
import Ginspiration from "./pages/Ginspiration";
import Grundrecept from "./pages/Grundrecept";
import GinShoppen from "./pages/GinShoppen";
import Ginskolan from "./pages/Ginskolan";
import Guide from "./pages/Guide";
import FAQ from "./pages/FAQ";
import "./custom.css";
import CookieConsent from "react-cookie-consent";
import Integritetspolicy from "./pages/Integritetspolicy";
import { NavLink as Link } from "react-router-dom";
import ShoppingCart from "./components/ShoppingCart";
import Media from "./pages/Media";
import Kassa from "./components/Kassa";

const App = () => {
  return (
    <Layout>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/faq" component={FAQ} />
        <Route exact path="/media" component={Media} />
        <Route exact path="/kassa" component={Kassa} />
        <Route exact path="/ginshoppen" component={GinShoppen} />
        <Route exact path="/ginskolan" component={Ginskolan} />
        <Route exact path="/varukorg" component={ShoppingCart} />
        <Route exact path="/grundrecept" component={Grundrecept} />
        <Route exact path="/ginspiration" component={Ginspiration} />
        <Route exact path="/guiden-hur-det-gar-till" component={Guide} />
        <Route exact path="/integritetspolicy" component={Integritetspolicy} />
        <Route
          exact
          path="/kop-och-leveransvillkor"
          component={KopOchLeveransvillkor}
        />
        {/* <Redirect exact from="/ginshoppen" to="/ginshoppen/recepten" /> */}
        <Route exact path="/ginshoppen/:page?" component={GinCooker} />
      </Switch>
      <CookieConsent
        debug={/* true */ false}
        expires={150}
        location="top"
        buttonText="Acceptera"
        buttonStyle={{
          color: "#fff",
          background: "#E4572E",
          borderRadius: "4px",
          padding: "8px 24px",
        }}
      >
        <h6>Den här sidan använder sig utav cookies.</h6>
        <Link to="/integritetspolicy" className="" style={{ color: "#52B0E8" }}>
          Läs mer på våran Integritetspolicy
        </Link>
      </CookieConsent>
    </Layout>
  );
};

export default App;
