import React, { useEffect, useState } from "react";
import styles from "./page_styles.module.scss";
import globalStyles from "../global.module.scss";
import Navbar from "../components/Navbar/Navbar";
import { marked } from "marked";
import client from "../services/contentful.js";
import VideoPagination from "../components/Pagination/VideoPagination/VideoPagination";
import Footer from "../components/Footer/Footer";

const Media = () => {
  const [isLoading, setisLoading] = useState(true);
  const [bannerImage, setBannerImage] = useState("");
  const [content, setContent] = useState([]);
  const [merOmGin, setMerOmGin] = useState([]);
  const [grundrecept, setGrundrecept] = useState([]);
  const [kundrecept, setKundrecept] = useState([]);

  useEffect(() => {
    const getMediaContent = () => {
      client.getEntry("6gO8ufNKwSM3DB1jVFkvht").then((entry) => {
        setContent(entry.fields);
        setBannerImage(entry.fields.bannerBild.fields.file.url);
      });

      client.getEntries({ content_type: "mediaVideo" }).then((entries) => {
        entries.items.forEach((video, index) => {
          if (video.fields.category.includes("Lär dig mer om Gin")) {
            setMerOmGin((oldArray) => [...oldArray, video.fields]);
          }
          if (video.fields.category.includes("Grundrecept")) {
            setGrundrecept((oldArray) => [...oldArray, video.fields]);
          }
          if (video.fields.category.includes("Kundrecept")) {
            setKundrecept((oldArray) => [...oldArray, video.fields]);
          }
        });
      });

      setisLoading(false);
    };

    getMediaContent();
  }, []);

  return (
    <>
      {isLoading ? (
        <></>
      ) : (
        <div className={styles.container}>
          <Navbar />
          <div
            className={styles.banner}
            style={{
              backgroundImage: `URL(https:${bannerImage})`,
              textAlign: "left",
            }}
          >
            <h1 style={{ color: "#000" }} className={globalStyles.max_width}>
              {content.title}
            </h1>
          </div>

          <div
            style={{ marginBottom: "42px" }}
            className={`${styles.media_extra} ${globalStyles.max_width}`}
            dangerouslySetInnerHTML={{
              __html: marked(
                content.beskrivning !== undefined || null
                  ? content.beskrivning
                  : ""
              ),
            }}
          />

          <div className={`${styles.media_extra} ${globalStyles.max_width}`}>
            {merOmGin.length > 0 && (
              <>
                <h2 className={styles.category_title}>Lär dig mer om Gin</h2>
                <VideoPagination videos={merOmGin} />
              </>
            )}

            {grundrecept.length > 0 && (
              <>
                <h2 className={styles.category_title}>Grundrecept</h2>
                <VideoPagination videos={grundrecept} />
              </>
            )}

            {kundrecept.length > 0 && (
              <>
                <h2 className={styles.category_title}>Kundrecept</h2>
                <VideoPagination videos={kundrecept} />
              </>
            )}
          </div>

          <div>
            <Footer />
          </div>
        </div>
      )}
    </>
  );
};

export default Media;
