import React, { useEffect, useState } from "react";
import styles from "./WizardConfMangd.module.scss";
import minus from "../../assets/icons/minus.png";
import plus from "../../assets/icons/plus.png";

const WizardConfMangd = ({
  data,
  max,
  min,
  value,
  decDisabled,
  incDisabled,
  onChangeFunc,
}) => {
  const [percentValue, setPercentValue] = useState();
  const { step, name, suggestedValue } = data;
  const safeMax = data.safeMax;

  const getPercentage = () => {
    var range = max - safeMax;
    var correctedStartValue = value - safeMax;
    var porcont = (correctedStartValue * 100) / range;

    if (porcont <= 0) {
      setPercentValue(0);
    } else if (porcont >= 100) {
      setPercentValue(100);
    } else {
      setPercentValue(porcont);
    }
  };

  useEffect(() => {
    getPercentage();
  }, [value]);

  const onChange = (range) => {
    onChangeFunc({
      type: data.name,
      value: Math.round(range * 10) / 10,
    });
  };

  const onDecrement = (value) => {
    if (value - step < min) {
      return;
    } else {
      onChange(value - step);
    }
  };

  const onIncrement = (value) => {
    if (value + step > max) {
      return;
    } else {
      onChange(value + step);
    }
  };

  return (
    <div className={styles.mangdconf}>
      <div className={styles.name}>
        <h5>{name}</h5>
        <span>({Math.round(suggestedValue * 10) / 10}g)</span>
      </div>
      <div
        className={styles.btn_group}
        style={{
          backgroundPosition: `0% ${percentValue}%`,
        }}
      >
        <button
          className={styles.decrement}
          onClick={() => onDecrement(value)}
          disabled={decDisabled}
        >
          <img src={minus} alt="decrement" />
        </button>
        <span className={`${styles.line} ${styles.line_one}`} />
        <p>{value}</p>
        <span className={`${styles.line} ${styles.line_two}`} />
        <button
          className={styles.increment}
          onClick={() => onIncrement(value)}
          disabled={incDisabled}
        >
          <img src={plus} alt="increment" />
        </button>
      </div>
      <div className={styles.separator} />
    </div>
  );
};

export default WizardConfMangd;
