import React, { useState, useEffect } from "react";
import styles from "./Bottles.module.scss";
import Cookies from "universal-cookie";
import minus from "../../assets/icons/minus.png";
import plus from "../../assets/icons/plus.png";

const cookies = new Cookies();

const Bottles = ({
  index,
  description,
  price,
  minimumOrder,
  bottleId,
  cartInfo,
  addBottlesToCart,
  fetchCart,
}) => {
  const [bottleAmnt, setBottleAmnt] = useState(0);
  const [cartId, setCartId] = useState();
  const [bottleInfo, setBottleInfo] = useState([]);

  useEffect(() => {
    findBottle(cartInfo);
  }, [cartInfo]);

  const findBottle = (array) => {
    if (!array.items) return;
    const result = array.items.find(({ name }) => name === description);
    if (result === undefined) return;
    setBottleInfo(result);
    setBottleAmnt(result.count);
    setCartId(result.id);
    return result;
  };

  const removeFromCart = (id) => {
    const deleteMethod = {
      method: "DELETE",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "X-Session-Id": cookies.get("recipe-session-id"),
      },
    };
    fetch(`publicapi/Cart/${id}`, deleteMethod)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        fetchCart();
        setBottleInfo([]);
        setBottleAmnt(0);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleBottles = (action) => {
    let bottlesInCart = 0;
    cartInfo.items &&
      cartInfo.items.forEach((item) => {
        if (item.type === 3) {
          bottlesInCart += 1;
        }
      });
    if (action === "add") {
      setBottleAmnt(Math.max(minimumOrder, bottleAmnt + 1));
      addBottlesToCart(bottleId, Math.max(minimumOrder, bottleAmnt + 1));
    } else if (action === "remove") {
      if (bottleAmnt === minimumOrder) {
        /* if (bottlesInCart <= 1) return; */
        removeFromCart(cartId);
      } else {
        setBottleAmnt(Math.max(minimumOrder, bottleAmnt - 1));
        addBottlesToCart(bottleId, Math.max(minimumOrder, bottleAmnt - 1));
      }
    }
  };

  return (
    <ul key={index}>
      {bottleInfo && bottleInfo.length !== 0 ? (
        <>
          <li>{bottleInfo.name}</li>
          <li>{bottleInfo.bottle.price} kr</li>
          <li className={styles.btn_group}>
            <button
              className={styles.decrement}
              onClick={() => handleBottles("remove")}
            >
              <img src={minus} alt="decrement" />
            </button>
            <span className={`${styles.line} ${styles.line_one}`} />
            <p>{bottleAmnt}</p>
            <span className={`${styles.line} ${styles.line_two}`} />
            <button
              className={styles.increment}
              onClick={() => handleBottles("add")}
            >
              <img src={plus} alt="increment" />
            </button>
          </li>
        </>
      ) : (
        <>
          <li>{description}</li>
          <li>{price} kr</li>
          <li className={styles.btn_group}>
            <button className={styles.decrement} disabled={true}>
              <img src={minus} alt="decrement" />
            </button>
            <span className={`${styles.line} ${styles.line_one}`} />
            <p>{bottleAmnt}</p>
            <span className={`${styles.line} ${styles.line_two}`} />
            <button
              className={styles.increment}
              onClick={() => handleBottles("add")}
            >
              <img src={plus} alt="increment" />
            </button>
          </li>
        </>
      )}
    </ul>
  );
};

export default Bottles;
