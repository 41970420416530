import React from "react";
import styles from "./GiftCard.module.scss";

const GiftCard = ({
  placeholder,
  namn,
  remove,
  onDecrease,
  amount,
  onIncrease,
}) => {
  return (
    <div className={styles.product_container}>
      <img onClick={() => remove()} src={placeholder} />
      <p className={styles.name}>{namn}</p>
      <div className={styles.btn_group}>
        <button
          className={`${styles.btn} ${styles.btn_minus}`}
          onClick={onDecrease}
        >
          -
        </button>

        <button className={`${styles.btn} ${styles.btn_primary}`}>
          {amount}:-
        </button>

        <button
          className={`${styles.btn} ${styles.btn_plus}`}
          onClick={onIncrease}
        >
          +
        </button>
      </div>
    </div>
  );
};

export default GiftCard;
